import React, { useEffect, useState } from 'react';
import PledgeCard from '../components/PledgeCard';
import placeholderImage from '../../assets/img/campaign.png';
import '../../assets/scss/Home.scss'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import SharedPledgeCard from '../components/SharedPledgeCard';
import { useNavigate, useParams } from 'react-router-dom';
import { serverUrl } from '../../serverUrl';

const generateRandomAmounts = () => {
  const raisedAmount = Math.floor(Math.random() * 200000) + 50000;
  const goalAmount = Math.floor(Math.random() * 500000) + 200000;
  const donationAmounts = [
    Math.floor(Math.random() * 500) + 100,
    Math.floor(Math.random() * 1000) + 500,
  ];

  return { raisedAmount, goalAmount, donationAmounts };
};

const SharedPledge = () => {
  const [amounts] = useState(generateRandomAmounts());
  const { id } = useParams()
  const [pledgeData, PledgeData] = useState({})

  // get the pledge data
  useEffect(() => {
    serverUrl.get(`/pledge/shared-pledge/?payment_code=${id}`)
      .then((res) => {
        PledgeData(res.data.results[0])
      })
      .catch((error) => {
        console.log(error)
      })

  },[id])

  return (
    <>
    <div className='homePage'>
      <SharedPledgeCard
        category="CAMPAIGN NAME"
        title={pledgeData.campaing_name}
        description={pledgeData.campaing_description}
        raisedAmount={pledgeData.total_pledge_paid}
        goalAmount={pledgeData.pledge_target}
        campaignRaised={pledgeData.total_campaign_paid}
        campaignGoal={pledgeData.campaign_amount}
        image={pledgeData.campaign_image}
        currency={pledgeData.pledge_currency}
      />
    </div>
    </>

  );
};

export default SharedPledge;