import React, { useState, useRef } from 'react';
import { Button, Card, Container, Row, Col, Image, Modal, Form, FormSelect } from 'react-bootstrap'
import '../../assets/scss/Campaign.scss';
import PieChart from './PieChart';
import Citam from '../../assets/img/citam_default.png'
import Coin from '../../assets/img/coin-stack.png'
import Calendar from '../../assets/img/calendar.png'
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'
import DatePicker from "react-datepicker";

const SelectedCampaign = ({ title, category, raisedAmount, goalAmount, donationAmounts, image }) => {
    const percentagePledge = (raisedAmount / goalAmount) * 100;
    const percentageCampaign = 30;
    const [selectedDate, setSelectedDate] = useState(null);

    const [showPledge, setShowPledge] = useState(false)
    const [fullName, setFullName] = useState('');

    const showPledgeOpen = () => {
        setShowAccounts(false)
        setShowPledge(true)
    }
    const showPledgeClose = () => {
        setShowPledge(false)
        setSelectedDate(null)
    }

    const [showMobileledge, setShowMobilePledge] = useState(false)

    const showMobilePledgeOpen = () => {
        setShowMobilePledge(true)
        setShowAccountsMobile(false)
    }

    const showMobilePledgeClose = () => {
        setShowMobilePledge(false)
    }

    const [showAccountledge, setShowAccountPledge] = useState(false)

    const showAccountPledgeOpen = () => {
        setShowAccountPledge(true)
    }

    const showAccountPledgeClose = () => {
        setShowAccountPledge(false)
    }
    const [pledgeType, setPledgeType] = useState('monentary')

    const handleChangePledgeType = (type) => {
        setPledgeType(type)
    }

    const [modeType, setModeType] = useState('one')

    const handleChangeModeType = (type) => {
        setModeType(type)
    }

    const [showAccounts, setShowAccounts] = useState(false);

    const handleAccountClose = () => setShowAccounts(false);
    const handleAccountShow = () => setShowAccounts(true);

    const [showAddAccounts, setShowAddAccounts] = useState(false);

    const handleAddAccountClose = () => {
        setShowAddAccounts(false)
        setShowAccounts(true)
        setAccountType('other')
    }

    const handleAddAccountShow = () => {
        setShowAddAccounts(true)
        setShowAccounts(false)
    }

    const [AccountType, setAccountType] = useState('mine')

    const handleChangeAccountType = (type) => {
        setAccountType(type)
    }



    const [AccountTypeAdd, setAccountTypeAdd] = useState(null)

    const handleChangeAccountTypeAdd = (type) => {
        setAccountTypeAdd(type)
    }

    const [AccountMobileType, setAccountMobileType] = useState(null)

    const handleChangeMobileAccountType = (type) => {
        // check the type of button clicked and display the modal accordingly
        if (type === 'mine') {
            setShowMobilePledge(true)
            setShowAccountPledge(false)

        }
        else if (type === 'other') {
            setShowAccountsMobile(true)
            setShowAccountPledge(false)

        }
        setAccountMobileType(type)
    }
    const [showAccountsMobile, setShowAccountsMobile] = useState(false);

    const handleAccountMobileClose = () => setShowAccountsMobile(false);
    const handleAccountMobileShow = () => setShowAccountsMobile(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [copied, setCopied] = useState(false);

    const url = `https://tig.citam.org/#/shared-pledge/${id}`;

    const handleCopyLink = () => {
        const textToCopy = `${url}`;
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setCopied(true);
        toast.success("Linked is copied");
    };
    const websiteUrl = `https://tig.citam.org/#/shared-pledge/${id}`;
    const appUrl = "https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app";

    const messageLink = `Hey there 😊! Click on the link below to assist ${fullName} in fulfilling there pledge.
    🔗 To donate, visit our website: ${websiteUrl}
    📱 Or download our app and contribute seamlessly: ${appUrl}`;;

    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        messageLink
    )}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        messageLink
    )}`;

    const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        messageLink
    )}`;

    const instagramLink = "https://www.instagram.com/direct/inbox/";

    function shareFacebook() {
        let url = encodeURIComponent(messageLink);
        let title = "title";
        window.open(
            `http://www.facebook.com/sharer.php?u=${url}`,
            "pop up name",
            "width=600, height=600"
        );
    }

    const handlePlatform = (platform) => {
        var Platform = platform;

        if (platform === "whatsapp") {
            window.open(whatsappLink, "_blank");
        } else if (platform === "facebook") {
            // Handle Facebook logic here
            // ...
        } else if (platform === "gmail") {
            window.open(EmailLink);
        } else if (platform === "x") {
            window.open(twitterLink, "_blank");
        } else if (platform === "instagram") {
            window.open(instagramLink, "_blank");
        } else if (platform === "copy_link") {
            handleCopyLink();
        }

    };



    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };
    let fillColorClass;

    if (percentagePledge < 33) {
        fillColorClass = '#e74c3c';
    } else if (percentagePledge < 67) {
        fillColorClass = '#f39c12';
    } else {
        fillColorClass = '#2ecc71';
    }
    return (
        <div className='mainCampaign'>
            {/* on the web view */}
            <div className="pledgeDisplay">
                <p className='categoryCampaign'>{category}</p>
                <h1>{title}</h1>

                <div className="image-container">
                    <img src={image} alt="Placeholder" className='TopDisplayImage' />
                </div>

                {/* the below container */}
                <Container>
                    <Card className='TopDisplayCard'>
                        <Card.Body>
                            <p className='w-10 text-white fw-bold' style={{ width: "11%", alignSelf: "center" }}> <span style={{ fontSize: "8px" }}>ENDS ON</span><br></br><span style={{ fontSize: "2rem" }}>07</span> September 2024</p>
                            <div className='d-flex  justify-content-between bg-white w-100' style={{ marginBottom: "-1rem", marginRight: "-1rem" }}>
                                <div className='p-4' style={{ alignSelf: "center", textAlign: "left" }}>
                                    <span className='text-uppercase'>Generosity in action</span><br></br>
                                    <span className='fw-bold text-uppercase'>Christian Responsibility and Stewardship</span>
                                </div>

                                <div className='d-flex w-50' style={{ alignItems: "center" }}>
                                    <div className='me-2'>
                                        <span className='text-muted fw-bold' style={{ fontSize: "0.8rem" }}>Days</span>
                                        <p className='fw-bold text-danger'>18</p>
                                    </div>

                                    <div className='me-2'>
                                        <span className='text-muted fw-bold' style={{ fontSize: "0.8rem" }}>Hour</span>
                                        <p className='fw-bold text-danger'>20</p>
                                    </div>

                                    <div className='me-2'>
                                        <span className='text-muted fw-bold' style={{ fontSize: "0.8rem" }}>Minutes</span>
                                        <p className='fw-bold text-danger'>08</p>
                                    </div>

                                    <div className='me-2'>
                                        <span className='text-muted fw-bold' style={{ fontSize: "0.8rem" }}>Seconds</span>
                                        <p className='fw-bold text-danger'>47</p>
                                    </div>
                                    <div className='d-flex justify-content-between ms-5'>
                                        <Button className='btn btn-sm btn-dark fw-bold text-white p-2 me-4' style={{ alignSelf: "center" }} onClick={handleAccountShow}>Pledge</Button>
                                        <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-2' style={{ alignSelf: "center" }} onClick={handleShow}>Share<i class="bi bi-share-fill ms-2"></i></Button>

                                    </div>

                                </div>


                            </div>
                        </Card.Body>
                    </Card>


                    {/* modal to show accounts */}
                    <Modal show={showAddAccounts} onHide={handleAddAccountClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-danger fw-bold m-auto'>Add Account</Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <Form className='makePledgeForm'>
                                <input
                                    type='text'
                                    placeholder='Enter Name'
                                    className='input-field'
                                />
                                <FormSelect>
                                    <option selected disabled>Select relation</option>
                                    <option>Child</option>
                                    <option>Other</option>
                                </FormSelect>

                                <Button className='btn btn-sm bg-transparent text-danger border-danger'>Select Photo</Button>
                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <p className='text-dark' onClick={handleAddAccountClose}>Close</p>
                            <p className='text-danger' onClick={handleAddAccountClose}>Save</p>
                        </Modal.Footer>

                    </Modal>

                    {/* modal to add account */}

                    <Modal show={showAccounts} onHide={handleAccountClose} centered>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body>
                            <Form className='makePledgeForm'>
                                <p style={{ fontSize: "14px" }}>Select Account</p>
                                <span style={{ fontSize: "12px", marginBottom: "0.8rem" }}>Please select the account you want to pledge for</span>
                                <div className='d-flex mt-2 mb-0'>
                                    <Button style={{ fontSize: "13px" }} className={`btn btn-sm  me-4 ${AccountType === 'mine' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                                        onClick={() => handleChangeAccountType('mine')}>My Account</Button>

                                    <Button style={{ fontSize: "13px" }} className={`btn btn-sm  ${AccountType === 'other' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                        onClick={() => handleChangeAccountType('other')}>Other Account</Button>
                                </div>



                                {AccountType === 'other' ?

                                    <>
                                        <FormSelect style={{ fontSize: "13px" }}>
                                            <option selected disabled>Select Account</option>
                                            <option>Peter Mwaura</option>
                                            <option>Faith Wambui</option>
                                        </FormSelect>
                                        <div className='d-flex justify-content-between'>
                                            <span style={{ fontSize: "12px", paddingTop: "0.5rem" }} onClick={handleAddAccountShow}>Add Account</span>
                                            <span style={{ fontSize: "12px", paddingTop: "0.5rem" }} className='text-danger' onClick={showPledgeOpen}>Pledge</span>
                                        </div>
                                    </>

                                    :
                                    <>
                                    </>
                                }


                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='btn btn-sm btn-secondary text-white' onClick={handleAccountClose}>Close</Button>
                            <Button className='btn btn-sm btn-danger text-white' onClick={showPledgeOpen}>Make Pledge</Button>
                        </Modal.Footer>

                    </Modal>

                    {/* modals to show the data */}
                    <Modal show={showPledge} onHide={showPledgeClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-danger'>Make pledge </Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <Form className='makePledgeForm'>
                                <p>Select the type of pledge</p>
                                <div className='d-flex'>
                                    <Button className={`btn btn-sm  me-4 ${pledgeType === 'monentary' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                                        onClick={() => handleChangePledgeType('monentary')}>Monetary</Button>

                                    <Button className={`btn btn-sm  ${pledgeType === 'in-kind' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                        onClick={() => handleChangePledgeType('in-kind')}>In-Kind</Button>
                                </div>
                                <p>Enter {pledgeType === 'monentary' ? 'pledge' : ' value of In-kind estimated'} amount</p>

                                <div>
                                    <input
                                        value='Ksh.'
                                        className='kshInput'
                                    />
                                    <input
                                        type='number'
                                        className='amountKshInput'
                                    />
                                </div>

                                {pledgeType === 'monentary' ?

                                    <>
                                        <p>Select the mode of redemption</p>
                                        <div className='d-flex'>
                                            <Button className={`btn btn-sm  me-4 ${modeType === 'one' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                                onClick={() => handleChangeModeType('one')}>One-Off</Button>

                                            <Button className={`btn btn-sm  ${modeType === 'regular' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                                onClick={() => handleChangeModeType('regular')}>Regular</Button>
                                        </div>
                                    </>

                                    :
                                    <>
                                        <p>Describe the In-Kind contribution</p>
                                        <textarea
                                            placeholder='Enter description'
                                            row={4}>

                                        </textarea>
                                    </>}

                                {modeType === 'regular' ?
                                    <>
                                        <p>Select redemption frequency</p>
                                        <FormSelect>
                                            <option>Every Week</option>
                                            <option>Every 2 Weeks</option>
                                            <option>Every Month</option>
                                            <option>Every 3 Months</option>
                                            <option>Every 6 Months</option>
                                            <option>Every Yearly</option>
                                        </FormSelect>
                                        <p>Enter redemption start date</p>
                                        <>
                                            {/* <input type='date' />
                                            <Image src={Calendar} style={{ width: "4%", marginRight: "0.5rem" }} />
                                            <span className='fw-bold text-danger mt-1'>2024-03-12</span> */}


                                            <Image
                                                src={Calendar}
                                                alt="Calendar"
                                                style={{ width: '4%', marginRight: '0.5rem', cursor: 'pointer' }}
                                            >


                                            </Image>
                                            <input
                                                type='date'
                                                className="calendarShow"
                                                onChange={handleDateChange}
                                                min={new Date().toISOString().split('T')[0]}
                                            />
                                            {selectedDate ? (
                                                <span className='fw-bold text-danger mt-1'>{selectedDate}</span>
                                            ) : (
                                                <span className='fw-bold text-danger mt-1'>Kindly select a date</span>
                                            )}



                                            <p>Enter the amount of each installment</p>
                                            <input
                                                type='number'
                                                className='inputNumberInstallement'
                                            />

                                        </>
                                    </>
                                    :

                                    <></>}



                                <p className='mt-2'>Opt in to pledge reminders</p>
                                <div>
                                    <>
                                        <input
                                            type='checkbox'

                                        />
                                        <span className='checked'>via SMS</span>
                                    </>

                                    <>
                                        <input
                                            type='checkbox'

                                        />
                                        <span className='checked'>via Email</span>
                                    </>
                                </div>
                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='btn btn-sm btn-secondary text-white' onClick={showPledgeClose}>Close</Button>
                            <Button className='btn btn-sm btn-danger text-white' onClick={showPledgeClose}>Make Pledge</Button>
                        </Modal.Footer>

                    </Modal>

                    {/* modal for sharing */}
                    <Modal size="lg" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-danger fw-bold m-auto'>Share this campaign</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("whatsapp")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid #9a1712",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Whatsapp}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on WhatsApp</p>
                                    </Col>
                                </div>

                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("facebook")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid #9a1712",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                        onClick={() => shareFacebook()}
                                    >
                                        <Image
                                            src={Facebook}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Facebook</p>
                                    </Col>
                                </div>
                            </Row>

                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("gmail")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid #9a1712",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Mail}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Gmail</p>
                                    </Col>
                                </div>

                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("x")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid #9a1712",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Twitter}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Twitter</p>
                                    </Col>
                                </div>
                            </Row>

                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("instagram")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid #9a1712",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Instagram}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Instagram</p>
                                    </Col>
                                </div>

                                <Col
                                    md={6}
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "44%",
                                        marginInline: "1.1rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                    onClick={() => handlePlatform("copy_link")}
                                >
                                    <div className="d-flex">
                                        <Image
                                            src={Paperclip}
                                            className="mb-2 me-2 mt-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Copy the link </p>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Card className='BottomDisplayCard'>
                        <Card.Body>
                            <Row>
                                <Col md={7} style={{ textAlign: "left" }}>
                                    <h4 className='text-uppercase'>Uniting hearts, spreading kindness, changing lives together.</h4>
                                    <p>TOGETHER IN GENEROSITY blooms from the depths of compassionate hearts, uniting individuals, communities, and organizations in a
                                        beautiful symphony of giving. It is a radiant beacon of hope, embodying the essence of empathy and altruism, igniting a shared
                                        commitment to uplift those in need. Through acts of kindness, charitable contributions, and selfless volunteerism, this heartfelt
                                        movement cultivates a sense of togetherness, weaving a tapestry of love and support. It celebrates the transformative power of generosity,
                                        painting a vibrant canvas of inclusivity and abundance, where every gesture, no matter how small, creates ripples of joy and change
                                        across the world</p>

                                </Col>

                                <Col md={5}>
                                    <div className='d-flex justify-content-between'>
                                        <div className='pie-chart-container'>
                                            <p className='fw-bold'>Campaign Progress</p>
                                            <PieChart
                                                percentage={percentagePledge}
                                            />
                                        </div>

                                        <div style={{ alignSelf: "center" }}>

                                            <span className='fw-bold' style={{ fontSize: "1rem" }}>Campaign Target</span><br></br>

                                            <p className='fw-bold' style={{ width: "10vw" }}><span className='text-danger'>KSH</span><span style={{ fontSize: "1.3rem" }}> 20,000</span></p>
                                        </div>




                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>



            </div>

            {/* on the mobile view */}
            <div className='pledeDisplayMobile'>
                <img src={image} alt="Placeholder" className='TopDisplayImage' />

                <div className="campaign-info">
                    <div className="campaign-header">
                        <h5>{title}</h5>
                        <div className="d-flex align-items-center">
                            <Image src={image} className='profileImage' />
                            <div className="donate-button">Joy Kiragu</div>
                        </div>
                    </div>

                    <Card className="campaign-details">
                        <Card.Body>
                            <div className='d-flex justify-content-between' style={{ marginTop: "-10px" }}>
                                <div className="my-pledge">My Pledge</div>
                                <div className="my-pledge">Contributed</div>

                            </div>
                            <div className='d-flex justify-content-between'>
                                <span className="amount fw-bold">Ksh. {goalAmount.toLocaleString()}</span>
                                <span className="amount text-danger fw-bold">Ksh. {raisedAmount.toLocaleString()}</span>
                            </div>

                            <div className="progress">
                                <div className={`progress-fill`} style={{ width: `${percentagePledge}%`, backgroundColor: fillColorClass }} />
                            </div>

                            <div className='d-flex justify-content-between'>
                                <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>300 days left</div>

                                <div className="days-left">
                                    <Image src={Coin} className='me-1' style={{ width: "12%" }} />
                                    83 contribution</div>
                            </div>

                        </Card.Body>
                    </Card>




                    <div className="about">
                        <h5>About Campaign</h5>
                        <p>TOGETHER IN GENEROSITY blooms from the depths of compassionate hearts, uniting individuals, communities, and organizations in a
                            beautiful symphony of giving. It is a radiant beacon of hope, embodying the essence of empathy and altruism, igniting a shared
                            commitment to uplift those in need. Through acts of kindness, charitable contributions, and selfless volunteerism, this heartfelt
                            movement cultivates a sense of togetherness, weaving a tapestry of love and support. It celebrates the transformative power of generosity,
                            painting a vibrant canvas of inclusivity and abundance, where every gesture, no matter how small, creates ripples of joy and change
                            across the world</p>
                    </div>

                    <div className="originator">
                        <h5>Originator</h5>
                        <div className='d-flex'>
                            <Image src={Citam} className='profileImage' />
                            <span>Citam</span>
                        </div>
                    </div>


                </div>
                <div className="actions">
                    <button className="btn btn-danger text-white" onClick={showAccountPledgeOpen}>Pledge</button>
                    <button className="btn btn-transparenr extdark border-dark" onClick={handleShow}>Share<i class="bi bi-share-fill ms-2"></i></button>
                </div>
            </div>

            {/* mobile account modal */}
            <Modal show={showAccountledge} className='footerModalAccount' onHide={showAccountPledgeClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Form className='makePledgeForm'>
                        <p style={{ fontSize: "14px" }}>Select Account</p>
                        <span style={{ fontSize: "12px", marginBottom: "0.8rem" }}>Please select the account you want to pledge for</span>
                        <div className='d-flex mt-2 mb-0'>
                            <Button style={{ fontSize: "13px" }} className={`btn btn-sm  me-4 ${AccountType === 'mine' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                                onClick={() => handleChangeMobileAccountType('mine')}>My Account</Button>

                            <Button style={{ fontSize: "13px" }} className={`btn btn-sm  ${AccountType === 'other' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                onClick={() => handleChangeMobileAccountType('other')}>Other Account</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* depending on the button clicked show the modal */}
            <Modal show={showAccountsMobile} onHide={handleAccountMobileClose} className="w-100 AccountsModal" style={{ maxWidth: "100vw !important" }}>
                <Modal.Body>
                    {AccountTypeAdd === 'Add' ?
                        <Form className='makePledgeForm p-3'>
                            <p className='text-center'>Add Account</p>
                            <input
                                type='text'
                                placeholder='Enter Name'
                                className='input-field'
                            />
                            <FormSelect>
                                <option selected disabled>Select relation</option>
                                <option>Child</option>
                                <option>Other</option>
                            </FormSelect>

                            <Button className='btn btn-sm bg-transparent text-danger border-danger'>Select Photo(Optional)</Button>

                            <div className='d-flex justify-content-end mb-5 mt-2'>
                                <p className='text-danger me-4' onClick={() => handleChangeAccountTypeAdd(null)}>Save</p>

                                <p className='text-dark ' onClick={handleAccountMobileClose}>Close</p>
                            </div>
                        </Form>
                        :
                        <Form className='makePledgeForm p-3'>
                            <p className='text-center'>Choose Account</p>

                            <FormSelect style={{ fontSize: "13px" }}>
                                <option selected disabled>Select Account</option>
                                <option>Peter Mwaura</option>
                                <option>Faith Wambui</option>
                            </FormSelect>
                            <div className='d-flex justify-content-between'>
                                <span style={{ fontSize: "12px", paddingTop: "0.5rem" }} onClick={() => handleChangeAccountTypeAdd('Add')}>Add Account</span>
                                <span style={{ fontSize: "12px", paddingTop: "0.5rem" }} className='text-danger' onClick={showMobilePledgeOpen}>Pledge</span>
                                <span style={{ fontSize: "12px", paddingTop: "0.5rem" }} onClick={handleAccountClose}>Close</span>

                            </div>
                        </Form>
                    }


                </Modal.Body>
            </Modal>


            {/* mobile modal */}
            <Modal show={showMobileledge} onHide={showMobilePledgeClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger' style={{ margin: "0 auto", fontWeight: "800" }}>Make pledge </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form className='makePledgeForm'>
                        <p>Select the type of pledge</p>
                        <div className='d-flex'>
                            <Button className={`btn btn-sm  me-4 ${pledgeType === 'monentary' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                                onClick={() => handleChangePledgeType('monentary')}>Monetary</Button>

                            <Button className={`btn btn-sm  ${pledgeType === 'in-kind' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                onClick={() => handleChangePledgeType('in-kind')}>In-Kind</Button>
                        </div>
                        <p>Enter {pledgeType === 'monentary' ? 'pledge' : 'value of In-kind estimated'} amount</p>

                        <div>
                            <input
                                value='Ksh.'
                                className='kshInput'
                            />
                            <input
                                type='number'
                                className='amountKshInput'
                            />
                        </div>

                        {pledgeType === 'monentary' ?

                            <>
                                <p>Select the mode of redemption</p>
                                <div className='d-flex'>
                                    <Button className={`btn btn-sm  me-4 ${modeType === 'one' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                        onClick={() => handleChangeModeType('one')}>One-Off</Button>

                                    <Button className={`btn btn-sm  ${modeType === 'regular' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                                        onClick={() => handleChangeModeType('regular')}>Regular</Button>
                                </div>
                            </>

                            :
                            <>
                                <p>Describe the In-Kind contribution</p>
                                <textarea style={{ width: "87.5vw" }}
                                    placeholder='Enter description'
                                    row={4}>


                                </textarea>
                            </>}

                        {modeType === 'regular' ?
                            <>
                                <p>Select redemption frequency</p>
                                <FormSelect>
                                    <option>Every Week</option>
                                    <option>Every 2 Weeks</option>
                                    <option>Every Month</option>
                                    <option>Every 3 Months</option>
                                    <option>Every 6 Months</option>
                                    <option>Every Yearly</option>
                                </FormSelect>
                                <p>Enter redemption start date</p>
                                <>

                                    <Image
                                        src={Calendar}
                                        alt="Calendar"
                                        style={{ width: '4%', marginRight: '0.5rem', cursor: 'pointer' }}
                                    >

                                    </Image>
                                    <input
                                        type='date'
                                        className="calendarShow"
                                        onChange={handleDateChange}
                                        min={new Date().toISOString().split('T')[0]}
                                    />
                                    {selectedDate ? (
                                        <span className='fw-bold text-danger mt-1'>{selectedDate}</span>
                                    ) : (
                                        <span className='fw-bold text-danger mt-1'>Kindly select a date</span>
                                    )}



                                    <p>Enter the amount of each installment</p>
                                    <input
                                        type='number'
                                        className='inputNumberInstallement'
                                    />

                                </>
                            </>
                            :

                            <></>}

                        <p className='mt-2'>Opt in to pledge reminders</p>
                        <div>
                            <>
                                <input
                                    type='checkbox'

                                />
                                <span className='checked'>via SMS</span>
                            </>

                            <>
                                <input
                                    type='checkbox'

                                />
                                <span className='checked'>via Email</span>
                            </>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-sm btn-danger text-white'>Make Pledge</Button>
                </Modal.Footer>

            </Modal>
        </div >


    );
};

export default SelectedCampaign;
