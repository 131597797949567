import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Image, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../../assets/scss/Sidebar.scss';
import { useNavigate } from "react-router";
import Logo from '../../assets/img/citam_default.png'
import User from '../../assets/img/user_1.png'
import {toast} from 'react-toastify'

function SideBar() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth <= 768) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [windowWidth]);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  // for logout purposes
  const navigate = useNavigate();
  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    toast.success('Successfull logged out !')
    navigate("/login");
  }
  const [showLeads, setShowLeads] = useState(false)
  const handleShowLead = () => {
    setShowLeads(!showLeads)
  }


  const [showRoles, setShowRoles] = useState(false)
  const handleShowRoles = () => {
    setShowRoles(!showRoles)
  }


  const [showUploads, setShowUploads] = useState(false)
  const handleShowUploads = () => {
    setShowUploads(!showUploads)
  }

  // Function to handle topics from PermissionComponent
  const handleTopics = (groupedTopics) => {
    const topics = Object.keys(groupedTopics);
    setTopics(topics);
  };


  return (
    <div fluid className="p-0 SideBar">
      <Navbar className="Logo">
        <button className="btn sidebar-toggle navbar-toggler-icon me-3 ms-3"
          onClick={handleToggleSidebar}

        ></button>
        <Navbar.Brand href="/">

          <Image src={Logo} className='' style={{ height: "6vh", marginTop: "-0.2rem", marginLeft: "0.5rem" }} />
        </Navbar.Brand>
      </Navbar>

      <div className={`sidebar ${showSidebar ? 'show' : ''}`}>

        <Nav defaultActiveKey="/" className="flex-column">
          <Nav.Link as={NavLink} to="/home">
            <i className="bi bi-house"></i>
            Home
          </Nav.Link>
          {/* <Nav.Link as={NavLink} to="/groups">
            <i className="bi bi-people"></i>
            Groups
          </Nav.Link> */}
          {/* <Nav.Link as={NavLink} to="/pledge">
            <i className="bi bi-disc"></i>
            Discover
          </Nav.Link> */}
          {/* <Nav.Link as={NavLink} to="/pledge">
            <i className="bi bi-disc"></i>
            Pledges
          </Nav.Link> */}
          {/* <Nav.Link as={NavLink} to="/more">
            <i className="bi bi-three-dots"></i>
            More
          </Nav.Link> */}
          <Nav.Link as={NavLink} to="/profile">
            <i className="bi bi-person"></i>
            Profile
          </Nav.Link>


          {/* <Nav.Link as={NavLink} to="/settings">
            <i className="bi bi-gear"></i>
            Setting
          </Nav.Link> */}

          <Nav.Link className='signout' onClick={handleLogout} >
            <i className="bi bi-box-arrow-left"></i>
            SignOut
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
}

export default SideBar;
