import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const PaymentCalendar = ({ installmentAmount, paymentFrequency, totalAmount, startDate, displayType }) => {
    const [paymentDates, setPaymentDates] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [nextPaymentDate, setNextPaymentDate] = useState(null);

    useEffect(() => {
        const calculatePaymentDates = () => {
            const dates = [];
            let currentDate = new Date(startDate);
            let remainingAmount = parseInt(totalAmount);

            // Calculate the payment dates until the total amount is paid off
            while (remainingAmount > 0) {
                dates.push(new Date(currentDate));
                remainingAmount -= parseInt(installmentAmount);

                switch (paymentFrequency) {
                    case 'weekly':
                        currentDate.setDate(currentDate.getDate() + 7);
                        break;
                    case '2weeks':
                        currentDate.setDate(currentDate.getDate() + 14);
                        break;
                    case 'monthly':
                        currentDate.setMonth(currentDate.getMonth() + 1);
                        break;
                    case '3months':
                        currentDate.setMonth(currentDate.getMonth() + 3);
                        break;
                    case '6months':
                        currentDate.setMonth(currentDate.getMonth() + 6);
                        break;
                    case 'yearly':
                        currentDate.setFullYear(currentDate.getFullYear() + 1);
                        break;
                    default:
                        break;
                }
            }

            // If the last installment is not equal to the installment amount, adjust the last date
            const lastInstallmentDate = new Date(dates[dates.length - 1]);
            dates.push(lastInstallmentDate);
            setPaymentDates(dates);


        };

        const calculateEndDate = () => {
            const dates = [];
            let currentDate = new Date(startDate);
            let remainingAmount = parseInt(totalAmount);

            // Calculate the payment dates until the total amount is paid off
            while (remainingAmount > 0) {
                dates.push(new Date(currentDate));
                remainingAmount -= parseInt(installmentAmount);

                switch (paymentFrequency) {
                    case 'weekly':
                        currentDate.setDate(currentDate.getDate() + 7);
                        break;
                    case '2weeks':
                        currentDate.setDate(currentDate.getDate() + 14);
                        break;
                    case 'monthly':
                        currentDate.setMonth(currentDate.getMonth() + 1);
                        break;
                    case '3months':
                        currentDate.setMonth(currentDate.getMonth() + 3);
                        break;
                    case '6months':
                        currentDate.setMonth(currentDate.getMonth() + 6);
                        break;
                    case 'yearly':
                        currentDate.setFullYear(currentDate.getFullYear() + 1);
                        break;
                    default:
                        break;
                }
            }

            // If the last installment is not equal to the installment amount, adjust the last date
            const lastInstallmentDate = new Date(dates[dates.length - 1]);
            dates.push(lastInstallmentDate);
            // Calculate and set the end date
            const endPaymentDate = new Date(lastInstallmentDate);
            setEndDate(endPaymentDate);
        };

        const calculateNextPaymentDate = () => {
            let currentDate = new Date(startDate);

            switch (paymentFrequency) {
                case 'weekly':
                    currentDate.setDate(currentDate.getDate() + 7);
                    break;
                case '2weeks':
                    currentDate.setDate(currentDate.getDate() + 14);
                    break;
                case 'monthly':
                    currentDate.setMonth(currentDate.getMonth() + 1);
                    break;
                case '3months':
                    currentDate.setMonth(currentDate.getMonth() + 3);
                    break;
                case '6months':
                    currentDate.setMonth(currentDate.getMonth() + 6);
                    break;
                case 'yearly':
                    currentDate.setFullYear(currentDate.getFullYear() + 1);
                    break;
                default:
                    break;
            }

            setNextPaymentDate(currentDate);
        };

        if (displayType === 'calendar') {
            calculatePaymentDates();
        } else if (displayType === 'end_date') {
            calculateEndDate();
        } else if (displayType === 'next_payment_date') {
            calculateNextPaymentDate();
        }
    }, [installmentAmount, paymentFrequency, startDate, totalAmount, displayType]);
useEffect(()=>{

    const date = new Date(endDate);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;

    localStorage.setItem('endDate', formattedDate)
    

},[endDate])
    // Render based on displayType
    if (displayType === 'calendar') {
        return (
            <div>
                <Calendar
                    tileContent={({ date }) => {
                        const paymentDate = paymentDates.find(d => d.toDateString() === date.toDateString());
                        if (paymentDate) {
                            // Check if the date is the start date
                            if (paymentDate.getTime() === new Date(startDate).getTime()) {
                                // If the date is the start date, display yellow background
                                return (
                                    <div className='activeTodayStart'>
                                        &nbsp;
                                    </div>
                                );
                            } else {
                                // For other payment dates, display red background
                                return (
                                    <i className='continueTodayStart'>
                                        &nbsp;
                                    </i>
                                );
                            }
                        }
                    }}
                />
            </div>
        );
    } else if (displayType === 'end_date') {
        return <>{endDate && <span>{endDate.toDateString()}</span>}</>;
    } else if (displayType === 'next_payment_date') {
        return <>{nextPaymentDate && <span>{nextPaymentDate.toDateString()}</span>}</>;
    } else {
        return null; // Return null for unknown displayType
    }
};

export default PaymentCalendar;
