import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ percentage }) => {
  let color;

  if (percentage < 33) {
    color = ['#e74c3c', '#9e9e9e30'];
  } else if (percentage >= 33 && percentage <= 67) {
    color = ['#f39c12', '#9e9e9e30']
  } else {
    color = ['#2ecc71', '#9e9e9e30'];
  }

  return (
    <div className="pie-chart-container" style={{ position: 'relative' }}>
      <ReactApexChart
        options={{
          labels: ['Raised Amount', 'Goal Amount'],
          colors: color,
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            lineCap: 'round',
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }}
        series={[percentage, 100 - percentage]}
        type="donut"
        width={300}
        height={150}
      />
      <div className="percentage-text" style={{ position: 'absolute', top: '30%', left: '58%', transform: 'translate(-50%, -50%)' }}>{percentage.toFixed(2)}%</div>
    </div>

  );
};

export default PieChart;