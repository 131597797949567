import React from 'react';
import Slider from 'react-slick';
import '../../assets/scss/OngoingCampaigns.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Button } from 'react-bootstrap';
import Image1 from '../../assets/img/campaign.jpeg'
import Image2 from '../../assets/img/campaign.png'
import Honesty from '../../assets/img/honesty.png'
import { Link } from 'react-router-dom';
function OngoingCampaigns() {
    const campaignsData = [
        {
            image: Image1,
            title: 'Together in generosity',
            start_date: "Mar 8 2023",
            end_date: "Dec 23 2024",
            days_remaining: "243",
            pledges: '2',
        },
        {
            image: Image2,
            title: 'Building a Better Future',
            start_date: "Apr 15 2023",
            end_date: "Nov 01 2024",
            days_remaining: "221",
            pledges: '5',
        }

    ];


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 50000
    };

    return (
      <>
        <div className='campaingOngoing'>
            <h2 className='text-danger tetx-start'>Ongoing Campaigns</h2>
            <Slider {...settings} className='sliderForCampaign'>
                {campaignsData.map((campaign, index) => (
                    <Link to='/campaign' className='text-decoration-none text-dark'>
                        <div key={index} >
                            <Image src={campaign.image} />
                            <div style={{ padding: "0.1rem" }}>
                                <h3 className='fw-bold'>{campaign.title}</h3>
                                <div className='dateTimeGetSpan'>
                                    <span>Start Date: {campaign.start_date}</span> |    <span>End Date: {campaign.end_date}</span>

                                </div>
                                <p className='fw-bold text-start' style={{ fontSize: "12px" }}>{campaign.days_remaining} day(s) left</p>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-50'>
                                        <Image src={Honesty} className='' style={{ width: "26%", height: "70%" }} />
                                        <p className='fw-bold text-start'>{campaign.pledges} Pledge(s)</p>
                                    </div>
                                    <Button className='btn btn-danger btn-sm text-white me-2' style={{ height: "5vh" }}>Pledge</Button>
                                </div>
                            </div>

                        </div>
                    </Link>



                ))}
            </Slider>
        </div>

        <div className='mobileCampaingOngoing'>
            <h2 className='text-danger tetx-start'>Ongoing Campaigns</h2>
            <Slider {...settings} className='sliderForCampaign'>
                {campaignsData.map((campaign, index) => (
                    <Link to='/campaign' className='text-decoration-none text-dark'>
                        <div key={index} >
                            <Image src={campaign.image} />
                            <div style={{ padding: "0.1rem" }}>
                                <h3 className='fw-bold'>{campaign.title}</h3>
                                <div className='dateTimeGetSpan'>
                                    <span>Start Date: {campaign.start_date}</span> |    <span className='ms-2'>End Date: {campaign.end_date}</span>

                                </div>
                                <p className='fw-bold text-start' style={{ fontSize: "12px" }}>{campaign.days_remaining} day(s) left</p>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-50'>
                                        <Image src={Honesty} className='' style={{ width: "26%", height: "70%" }} />
                                        <p className='fw-bold text-start'>{campaign.pledges} Pledge(s)</p>
                                    </div>
                                    <Button className='btn btn-danger btn-sm text-white me-2' style={{ height: "3vh",padding:"0",width:"7vh" }}>Pledge</Button>
                                </div>
                            </div>

                        </div>
                    </Link>



                ))}
            </Slider>
        </div>
      </>
    );
}

export default OngoingCampaigns;
