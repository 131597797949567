import { FETCH_CAMPAIGN_DATA_SUCCESS, FETCH_PLEDGE_DATA_SUCCESS } from './actions';

const campaignReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const pledgeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PLEDGE_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { campaignReducer, pledgeReducer };
