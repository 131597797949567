// Redux actions.js

export const FETCH_CAMPAIGN_DATA_SUCCESS = 'FETCH_CAMPAIGN_DATA_SUCCESS';
export const FETCH_PLEDGE_DATA_SUCCESS = 'FETCH_PLEDGE_DATA_SUCCESS';
export const UPDATE_CAMPAIGN_DATA = 'UPDATE_CAMPAIGN_DATA';
export const UPDATE_PLEDGE_DATA = 'UPDATE_PLEDGE_DATA';

export const fetchCampaignDataSuccess = (data) => ({
    type: FETCH_CAMPAIGN_DATA_SUCCESS,
    payload: data,
});

export const fetchPledgeDataSuccess = (data) => ({
    type: FETCH_PLEDGE_DATA_SUCCESS,
    payload: data,
});


