import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'; // Import the CSS

function ImageCropper({ src }) {
  const [crop, setCrop] = useState({
    aspect: 1, // Set aspect ratio to 1 for a square crop
  });

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area (coordinates, width, height)
    // You can save this information or create a cropped image here
    console.log('Cropped area:', croppedArea);
    console.log('Cropped area in pixels:', croppedAreaPixels);
  };

  return (
    <div>
      <ReactCrop
        src={src} // Pass the image source (URL, blob, or base64 data)
        crop={crop}
        onChange={handleCropChange}
        onComplete={handleCropComplete}
      />
      {/* You can display the cropped image here */}
    </div>
  );
}

export default ImageCropper;