import React, { useEffect, useState } from 'react';
import placeholderImage from '../../assets/img/campaign.png';
import '../../assets/scss/Home.scss'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import NewHomePage from './NewHomePage';
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { useNavigate } from 'react-router-dom';

const generateRandomAmounts = () => {
  const raisedAmount = Math.floor(Math.random() * 200000) + 50000;
  const goalAmount = Math.floor(Math.random() * 500000) + 200000;
  const donationAmounts = [
    Math.floor(Math.random() * 500) + 100,
    Math.floor(Math.random() * 1000) + 500,
  ];

  return { raisedAmount, goalAmount, donationAmounts };
};

const NewHome = () => {
  const [amounts] = useState(generateRandomAmounts());
  const navigate = useNavigate()
  const accessToken = localStorage.getItem('authTokens');
  if (!accessToken) {
    window.location.href = '/#/login';
    return;
  }
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;

  const [campaignData, setCampaignData] = useState({})
  const [pledgeData, setPledgeData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch campaign data
        const campaignResponse = await serverUrl.get('/pledge/giving_campaign_list/?campaign_id=1', config);
        setCampaignData(campaignResponse.data[0]);
  
        // Fetch pledge data
        const pledgeResponse = await serverUrl.get(`/pledge/get-user-total-pledges/?user_id=${userId}`, config);
        setPledgeData(pledgeResponse.data.results[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    // Fetch data initially
    fetchData();
  
    // Fetch data every 5 seconds
    // const intervalId = setInterval(fetchData, 3000);
  
    // // Clean up interval on unmount
    // return () => clearInterval(intervalId);
  }, [userId]);
  
  return (
    <>
      <SideBar />
      <Header />
      <div className='homePage'>
        <NewHomePage
          category=""
          title={campaignData.campaign_name}
          days_remaining={campaignData.remaining_months}
          contributions={campaignData.total_pledged_count}
          raisedAmount={campaignData.total_raised}
          pledgeGoal={pledgeData.total_pledged}
          pledgeRaised={pledgeData.total_paid}
          percentage_collected_against_pledges = {campaignData.percentage_collected_against_pledges}
          goalAmount={parseInt(campaignData.campaign_target_amount)}
          donationAmounts={amounts.donationAmounts}
          image={campaignData.image}
          ends_on={campaignData.expiry_date}
          description={campaignData.campaign_description}
          pledge_amount={campaignData.pledged_amount}
          total_contribution={campaignData.total_contribution}
        />
      </div>
    </>
  );
};

export default NewHome;
