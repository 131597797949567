import React, { useState } from 'react';
import { Form, Button, Image, Row, Col, Spinner } from 'react-bootstrap';
import '../../assets/scss/ForgotPassword.scss';
import { Link, useNavigate } from "react-router-dom";
import Citam from '../../assets/img/citam_default.png';
import { serverUrl } from '../../serverUrl';
import PhoneNumberValidation from '../components/PhoneNumberValidation';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [idNumber, setIdNumber] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const [resetOption, setResetOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your login logic here
  };

  const handleOptionChange = (option) => {
    setResetOption(option);
  };
  const phone_number = localStorage.getItem('number')

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = resetOption === 'email' ? { email: idNumber } : { phone_number: '+' + phone_number };
    setIsLoading(true)
    // Send the data
    serverUrl.post(`/user/request-reset-password/`, data)
      .then((res) => {
        toast.success("We have sent you a link to reset your password");
        navigate('/login')
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // password reset mobile
  const [validationResult, setValidationResult] = useState('');

  const validateInput = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const digitPattern = /^\d+$/;

    if (emailPattern.test(value)) {
      setValidationResult('email');
    } else if (digitPattern.test(value)) {
      setValidationResult('digits');
    } else {
      setValidationResult('Invalid input');
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setMobileValue(value);
    validateInput(value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    const data = validationResult === 'email' ? { email: mobileValue } : { phone_number: mobileValue };
    if (validationResult === 'Invalid input') {
      toast.error('Enter either email or phone number')
      return null
    }
    // Send the data
    setIsLoading(true)
    serverUrl.post(`/user/request-reset-password/`, data)
      .then((res) => {
        toast.success("We have sent you a link to reset your password");
        navigate('/login')
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className='mainLogin'>
      <div className='LoginPage'>
        <Image src={Citam} style={{ width: "5%" }} />
        <Row>
          <Col md={6}>
            <Form onSubmit={handleSubmit} className='forgotPasswordForm'>
              <h2 className="mb-4">Forgot Password</h2>
              <div className="forgot-options mb-4">
                <p className="mb-3">Choose how you want to receive the reset link:</p>
                <div className="option-container">
                  {resetOption === 'sms' && (
                    <div
                      className={`option ${resetOption === 'sms' ? 'active' : ''}`}
                      onClick={() => handleOptionChange('sms')}
                    >
                      <span className="option-text"><i className='bi bi-telephone'></i> Receive via SMS</span>
                    </div>
                  )}
                  {resetOption === 'email' && (
                    <div
                      className={`option ${resetOption === 'email' ? 'active' : ''}`}
                      onClick={() => handleOptionChange('email')}
                    >
                      <span className="option-text"><i className='bi bi-envelope'></i> Receive via Email</span>
                    </div>
                  )}
                  {resetOption === null && (
                    <>
                      <div
                        className={`option ${resetOption === 'sms' ? 'active' : ''}`}
                        onClick={() => handleOptionChange('sms')}
                      >
                        <span className="option-text"><i className='bi bi-telephone'></i> Receive via SMS</span>
                      </div>
                      <div
                        className={`option ${resetOption === 'email' ? 'active' : ''}`}
                        onClick={() => handleOptionChange('email')}
                      >
                        <span className="option-text"><i className='bi bi-envelope'></i> Receive via Email</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {resetOption === 'sms' && (
                <PhoneNumberValidation className='mobileCountryPicker' />
              )}
              {resetOption === 'email' && (
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                />
              )}
              {isLoading ?
                <Button className='btn btn-danger text-white'><Spinner /></Button>
                :
                <Button type="submit" className='btn btn-danger text-white'>RESET PASSWORD</Button>

              }
            </Form>
          </Col>
          <Col md={6}>
            <div className='curvedSpan'>
              <div className='textDisplay'>
                <div className='InnerDivDisplay'>
                  <h2>Welcome to our Together in generosity Campaign!</h2>
                  <div className='displayParagraph'>
                    <br></br>
                    <p>Together in Generosity (TIG) Campaign is to raise the funds/resources to facilitate the settling of 23 assemblies that are in tents while others are on leased premises</p>
                    <p><b>Reset your password now </b>to access exclusive updates, participate in fundraising events, and contribute to our mission of spreading hope and kindness. Every action you take brings us one step closer to creating positive change.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>


      <div className='LoginPageMobile'>
        <Image src={Citam} />
        <Form onSubmit={handleForgotPassword} className='loginForm'>
          <h2>Forgot Password</h2>
          <label controlId="idNumber" className="mb-3">Enter Phone Number or Email</label>
          <Form.Control
            type="text"
            value={mobileValue}
            onChange={handleChange}
          />
          {isLoading ?
            <Button className='btn btn-danger text-white'><Spinner /></Button>
            :
            <Button className='btn btn-danger text-white' type='submit'>RESET PASSWORD</Button>
          }
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
