import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutes from './WebRoutes';
import { Provider } from 'react-redux';
import store from '../src/redux/store.js';

function App() {
  // const [isActive, setIsActive] = useState(true);
  // const LoggedIn = localStorage.getItem('authTokens');

  // useEffect(() => {
  //   let inactivityTimer;
  //   let toastId;

  //   const resetTimer = () => {
  //     clearTimeout(inactivityTimer);
  //     inactivityTimer = setTimeout(() => {
  //       setIsActive(false);
  //       // Show toast when user becomes inactive
  //       if (LoggedIn && !toast.isActive(toastId)) {
  //         toastId = toast.warning("You have been inactive for some time. To ensure your account's security, you will be logged out in 30 seconds. Please continue with your activity to stay logged in.", {
  //           autoClose: 30000,
  //         });

  //         setTimeout(() => {
  //           localStorage.removeItem("authTokens");
  //           window.location.href = '/#/login';
  //         }, 10000);
  //       }
  //     }, 150000);
  //   // }, 150000);
  //   };

  //   const handleActivity = () => {
  //     setIsActive(true);
  //     clearTimeout(inactivityTimer);
  //     resetTimer();
  //     // Close the toast if it's active
  //     if (toast.isActive(toastId)) {
  //       toast.dismiss(toastId);
  //     }
  //   };

  //   // Set initial timer
  //   resetTimer();

  //   // Event listeners to detect user activity
  //   const activityEvents = ['mousemove', 'keypress', 'click', 'scroll', 'touchstart', 'touchmove', 'resize', 'mouseenter', 'mouseleave', 'mousedown', 'mouseup', 'keydown', 'keyup', 'contextmenu', 'focus'];

  //   activityEvents.forEach(event => {
  //     window.addEventListener(event, handleActivity);
  //   });

  //   // Cleanup
  //   return () => {
  //     clearTimeout(inactivityTimer);
  //     activityEvents.forEach(event => {
  //       window.removeEventListener(event, handleActivity);
  //     });
  //   };
  // }, [LoggedIn]); 

  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer />
        <WebRoutes />
      </Provider>
    </div>
  );
}

export default App;
