import React, { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap';
import User from '../../assets/img/user_1.png'
import '../../assets/scss/Header.scss'
import Phone from '../../assets/img/phone.png'
import Chat from '../../assets/img/colored-live-chat.png'
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';

function Header() {
    const navigate = useNavigate()
    // get the user details, including the username
    const [fullName, setFullName] = useState('');
    const [image, setImage] = useState('');
    const [messageCount, setMessageCount] = useState('');

    useEffect(() => {
        const accessToken = localStorage.getItem('authTokens');
        // if not logged in, redirect to login automatically
        if (!accessToken) {
            navigate('/');
            return;
        }
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` },
        };
        // decoding the token to get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    
        const fetchData = () => {
            // hitting the endpoint to get the user's details
            serverUrl
                .get(`/user/user-details/?id=${userId}`, config)
                .then((res) => {
                    // get the full name of the user
                    setFullName(res.data.results[0].full_name);
                    setImage(res.data.results[0].profile_picture);
                    // get the count for the help issue
                    const phoneNumber = encodeURIComponent(res.data.results[0].phone_number);
    
                    serverUrl.get(`/user/get-user-support-requester-id/?phone_number=${phoneNumber}`)
                        .then((res) => {
                            // console.log(res.data.help_requester);
                            // console.log(res.data.user);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
    
                })
                .catch((error) => {
                    console.log(error);
                });
    
            // get the unread messages
            serverUrl.get(`/user/get-unread-conversation?user_id=${userId}`)
                .then((res) => {
                    setMessageCount(res.data.unread_count)
                })
                .catch((error) => {
                    console.log(error);
                });
        };
    
        // Initial fetch
        fetchData();
    
        // Polling every 3 seconds
        // const intervalId = setInterval(fetchData, 3000);
    
        // Cleanup
        // return () => clearInterval(intervalId);
    }, []);
    

    return (
        <div className='Header'>
            <div className='profileView'>

                <div className='webView'>
                    <p>{fullName}</p>
                    <Image src={image} />
                </div>

                <div className='mobileView'>


                    <div>
                        <Link to='/buy-airtime' className='text-decoration-none'>
                            <Image src={Phone} className='imageProfiles' />
                            <span className='bg-dark text-white bonus-span'>Bonus</span>
                            <p className='imageProfilesParagraph' >Airtime</p>
                        </Link>
                    </div>
                    <div>
                        <Link to='/help-desk' className='text-decoration-none'>
                            <Image src={Chat} className='imageProfiles' />
                            {messageCount !== 0 && (
                                <span className='bg-danger text-white desk-span'>{messageCount}</span>
                            )}
                            <p className='imageProfilesParagraph' >Help Desk</p>

                        </Link>
                    </div>
                    <div>
                        <Link to='/profile' className='text-decoration-none'>
                            <Image src={image} className='imageProfiles' />
                            <p className='imageProfilesParagraph' >Profile</p>
                        </Link>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Header