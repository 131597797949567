import React from 'react'
import Header from '../components/Header';
import SideBar from '../components/Sidebar';


function Groups() {
  return (
    <>
      <Header />
      <SideBar />
      <div style={{ padding: "6rem" }}>
<h1> COMING SOON</h1>
      </div>

    </>
  )
}

export default Groups