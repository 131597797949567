import React from 'react'
import OngoingCampaigns from '../components/OngoingCampaigns'
import MyPledges from '../components/MyPledges'

function MobilePledgeCampaign() {
  return (
    <div className='p-3'>
        <i className='bi bi-chevron-left float-start w-100 text-start fs-1 fw-bold'></i>
        <h1 className='text-start fw-bold mb-2'>Pledge</h1>
        <OngoingCampaigns/>
        <MyPledges/>
    </div>
  )
}

export default MobilePledgeCampaign