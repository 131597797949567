import React, { useState, useEffect } from 'react';
import '../../assets/scss/Donation.scss';
import { Button, Image, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import PayPal from '../../assets/img/paypal.png'
import Bank from '../../assets/img/bank.png'
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import Cash from '../../assets/img/money.png'
import Mpesa from '../../assets/img/mpesa.jpeg'
import Cheque from '../../assets/img/cheque.png'
import Credit from '../../assets/img/credit-card.png'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'
import Select from 'react-select'
import Shutter from '../../assets/img/shutter.png'

function DonationShared() {
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [payLoading, setPayloading] = useState(false)


    const [pledgeStatement, setPledgeStatements] = useState([])
    const [contributorStatement, setContributorStatements] = useState([])
    const [campaignStatement, setCampaignStatements] = useState({})
    const [userChurch, setUserChurch] = useState({})
    const [fullName, setFullName] = useState('');
    const foundContributor = localStorage.getItem('contributorNumber')
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [phoneNumberOther, setPhoneNumberOther] = useState([])

    const [chequeData, setChequeData] = useState({
        chequeNumber: "",
        currency: "KSH",
        transaction_date: "",
        amount: "",
    })
    const [inKindData, setInkindData] = useState({
        description: "",


    })

    const banks = [
        {

            "name": "KCB"
        },
        {

            "name": "Standard Chartered"
        },
        {

            "name": "ABSA"
        },
        {

            "name": "NCBA"
        },
        {

            "name": "Prime Bank"
        },
        {

            "name": "Coop Bank"
        },
        {

            "name": "NBK"
        },
        {

            "name": "M-Oriental"
        },
        {

            "name": "Citi Bank"
        },
        {

            "name": "Middle East Bank"
        },
        {

            "name": "Bank of Africa"
        },
        {

            "name": "Consolidated Bank"
        },
        {

            "name": "Credit Bank"
        },
        {

            "name": "Stanbic Bank"
        },
        {

            "name": "ABC Bank"
        },
        {

            "name": "Ecobank"
        },
        {

            "name": "Paramount"
        },
        {

            "name": "Kingdom Bank"
        },
        {

            "name": "GT Bank"
        },
        {

            "name": "Victoria Bank"
        },
        {

            "name": "Guardian Bank"
        },
        {

            "name": "I&M Bank"
        },
        {

            "name": "Housing Finance"
        },
        {

            "name": "DTB"
        },
        {

            "name": "Mayfair Bank"
        },
        {

            "name": "Sidian Bank"
        },
        {

            "name": "Equity Bank"
        },
        {

            "name": "Family Bank"
        },
        {

            "name": "Gulf African Bank"
        },
        {
            "name": "Premier Bank Kenya"
        },
        {

            "name": "Dubai Islamic Bank"
        },
        {

            "name": "UBA"
        },
        {

            "name": "KWFT"
        },
        {

            "name": "Stima Sacco"
        },
        {

            "name": "Telkom Kenya"
        },
        {

            "name": "Choice Microfinance Bank"
        },
        {

            "name": "Bank of Baroda"
        },
    ]

    const [selectedBank, setSelectedBank] = useState(null);

    const optionList = banks.map(bank => ({
        value: bank.name,
        label: bank.name
    }));

    const handleSelectChange = selectedOption => {
        setSelectedBank(selectedOption);
    };

    const handlePledgeInput = (e) => {
        const { name, value } = e.target;

        setChequeData({
            ...chequeData,
            [name]: value,
        });

        setInkindData({
            ...inKindData,
            [name]: value,
        });
    };
    const [userPledgeData, setUserPledgeData] = useState({})
    useEffect(() => {
        if (id) {

            serverUrl.get(`/pledge/shared-pledge/?payment_code=${id}`)
                .then((res) => {
                    setUserPledgeData(res.data.results[0])
                })
                .catch((error) => {
                    console.log(error)
                })
        }



    }, [id])

    let defaultPhone;
    if (userPledgeData) {
        defaultPhone = parseInt(userPledgeData.phone_number)
    }
    else if (foundContributor) {
        defaultPhone = foundContributor
    }
    else {
        defaultPhone = ''
    }



    const [phoneNumber, setPhoneNumber] = useState('');


    useEffect(() => {
        if (userPledgeData && userPledgeData.phone_number && !contributorPhone) {
            // Remove "+" and "254" prefix and replace with "0" if present
            const defaultPhone = userPledgeData.phone_number.startsWith("+254")
                ? "0" + userPledgeData.phone_number.slice(4)
                : userPledgeData.phone_number;

            setPhoneNumber(defaultPhone);
        }
        else if (contributorPhone) {
            const defaultPhone = contributorPhone.startsWith("+254")
                ? "0" + contributorPhone.slice(4)
                : contributorPhone;

            setPhoneNumber(defaultPhone);
        }
    }, [userPledgeData]);

    const handlePhoneNumber = (e) => {
        // if (!phoneNumber) {
        //     setPhoneNumber(defaultPhone);
        // } else {
        //     setPhoneNumber(e.target.value);
        // }
        setPhoneNumber(e.target.value);

    };

    const [images, setImages] = useState([]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        setImages(file);

    };
    const [modalShow, setModalShow] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('MPESA');

    const handleOpenRedeemModal = () => {
        setModalShow(true);

    }
    const handleShowModal = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod);
    };

    const handleCloseModal = () => {
        setModalShow(false);
        setSelectedPaymentMethod('');
    };
    const [donationAmount, setDonationAmount] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [bankTransferOption, setBankTransferOption] = useState('PESALINK');
    const handleButtonClick = (index, amount) => {
        setActiveIndex(index);
        setDonationAmount(amount)
    };
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleBankTransferOptionChange = (option) => {
        setBankTransferOption(option);
    };

    const handleBankTransferSelect = (option) => {
        handleBankTransferOptionChange(option);
        setSelectedPaymentMethod(option);
    };

    // get post the data for the redeemption
    const contributor = localStorage.getItem('contributor')
    const contributorPhone = localStorage.getItem('contributorNumber')
    const contributorName = localStorage.getItem('contributorName')

    function handleRedeemAdd(e) {
        let description;
        if (contributor) {
            description = `${contributor}-1-${id}`

        }
        else {
            description = `id-1-${id}`

        }
        e.preventDefault()
        if (selectedPaymentMethod === 'MPESA') {

            let formattedPhoneNumber = phoneNumber.trim();
            if (formattedPhoneNumber.startsWith('0')) {
                formattedPhoneNumber = '+254' + formattedPhoneNumber.slice(1);
            }
            if (!formattedPhoneNumber.startsWith('+254')) {
                formattedPhoneNumber = '+254' + formattedPhoneNumber;
            }
            if (contributorPhone) {
                formattedPhoneNumber = contributorPhone
            }
            if (!phoneNumber || !donationAmount) {
                // Toast an error message if any of the fields are missing
                toast.error("Please fill in all required fields.");
                return;
            }

            const data = {
                amount: donationAmount,
                phone_number: formattedPhoneNumber,
                reference: id,
                description: description,
                source: "web",

            }
            if (contributor) {
                data.updated_by = null
                data.first_name = contributorName
            }
            else {
                data.updated_by = userPledgeData.plede_user_id
            }

            if (userChurch) {
                data.church = userChurch.id;
            }
            setPayloading(true)
            serverUrl.post(`giving/checkout/`, data)
                .then((res) => {
                    setSelectedPaymentMethod(null);
                    toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
                    // Reload the page after 8 seconds
                    setDonationAmount('')
                    setPayloading(false)
                    setTimeout(() => {
                        if (contributor) {
                            localStorage.removeItem('contributor')
                            localStorage.removeItem('contributorName')
                            localStorage.removeItem('contributorNumber')
                            navigate(`/shared-pledge/${id}`)
                        }
                        else {
                            toast.warning('Press Done to go back to Citam App', {
                                position: 'bottom-center',
                                className: 'bg-warning text-white',
                                autoClose: 90000
                            });
                            setDonationAmount(null)

                        }

                    }, 3000);


                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else if (selectedPaymentMethod === 'CHEQUE') {
            let transactionDate = null; // Initialize transaction date to null

            if (chequeData && chequeData.transaction_date) {
                // If transaction date exists in chequeData
                transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
            } else {
                // If transaction date is null, use the current date
                transactionDate = new Date(); // Current date
            }

            // Format the date to YYYYMMDD format
            const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');


            const sharedUser = userPledgeData.shared_user;

            let firstName = null;
            let surName = null;

            if (sharedUser.includes(' ')) {
                const [firstPart, secondPart] = sharedUser.split(' ');
                firstName = firstPart;
                surName = secondPart;
            } else {
                firstName = sharedUser.charAt(0);
            }





            if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
                // Toast an error message if any of the fields are missing
                toast.error("Please fill in all required fields.");
                return;
            }
            const formData = new FormData();
            formData.append("payment_code", id);
            formData.append("cheque_number", chequeData.chequeNumber);
            formData.append("currency", chequeData.currency);
            formData.append("transcation_amount", parseInt(donationAmount));
            formData.append("transaction_date", chequeData.transaction_date);
            formData.append("transcation_time", formattedDate + '000000');
            formData.append("payment_channel", "cheque");
            formData.append("phone_number", phoneNumber);
            formData.append("status", "pending");
            formData.append("source", "web");
            formData.append("first_name", firstName);
            formData.append("surname", surName);
            formData.append("bill_ref_number", id);


            // Check if cheque image is present
            if (images) {
                formData.append("cheque_image", images);
            }

            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, formData)
                .then((res) => {
                    console.log(res.data)
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`)
                        .then((res) => {
                            setPledgeStatements(res.data.results[0].pledge_statement)
                            setContributorStatements(res.data.results[0].contributor)
                            setCampaignStatements(res.data.results[0].campaign_data[0])
                            setPayloading(false)
                            setTimeout(() => {
                                if (contributor) {
                                    localStorage.removeItem('contributor')
                                    localStorage.removeItem('contributorName')
                                    localStorage.removeItem('contributorNumber')
                                    navigate(`/shared-pledge/${id}`)
                                }
                                toast.warning('Press Done to go back to Citam App', {
                                    position: 'bottom-center',
                                    className: 'bg-warning text-white',
                                    autoClose: 90000
                                });
                            }, 3000);

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedPaymentMethod === 'CARD') {
            let transactionDate = null; // Initialize transaction date to null

            if (chequeData && chequeData.transaction_date) {
                // If transaction date exists in chequeData
                transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
            } else {
                // If transaction date is null, use the current date
                transactionDate = new Date(); // Current date
            }
            if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
                // Toast an error message if any of the fields are missing
                toast.error("Please fill in all required fields.");
                return;
            }
            // Format the date to YYYYMMDD format
            const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');

            const sharedUser = userPledgeData.shared_user;

            let firstName = null;
            let surName = null;

            if (sharedUser.includes(' ')) {
                const [firstPart, secondPart] = sharedUser.split(' ');
                firstName = firstPart;
                surName = secondPart;
            } else {
                firstName = sharedUser.charAt(0);
            }

            const formData = new FormData();
            formData.append("payment_code", id);
            formData.append("transcation_id", chequeData.chequeNumber);
            formData.append("currency", chequeData.currency);
            formData.append("transcation_amount", parseInt(donationAmount));
            formData.append("transaction_date", chequeData.transaction_date);
            formData.append("transcation_time", formattedDate + '000000');
            formData.append("payment_channel", "card");
            formData.append("phone_number", phoneNumber);
            formData.append("status", "pending");
            formData.append("source", "web");
            formData.append("first_name", firstName);
            formData.append("bill_ref_number", id);


            // Check if cheque image is present
            if (images) {
                formData.append("cheque_image", images);
            }

            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, formData)
                .then((res) => {
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`)
                        .then((res) => {
                            setPledgeStatements(res.data.results[0].pledge_statement)
                            setContributorStatements(res.data.results[0].contributor)
                            setCampaignStatements(res.data.results[0].campaign_data[0])
                            setDonationAmount(null)
                            setPayloading(false)
                            setTimeout(() => {
                                if (contributor) {
                                    localStorage.removeItem('contributor')
                                    localStorage.removeItem('contributorName')
                                    localStorage.removeItem('contributorNumber')
                                    navigate(`/shared-pledge/${id}`)
                                }
                                toast.warning('Press Done to go back to Citam App', {
                                    position: 'bottom-center',
                                    className: 'bg-warning text-white',
                                    autoClose: 90000
                                });
                            }, 3000);

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedPaymentMethod === 'BANK TRANSFER') {
            const formattedDate = chequeData.transaction_date.replace(/-/g, '');

            const sharedUser = userPledgeData.shared_user;

            let firstName = null;
            let surName = null;

            if (sharedUser.includes(' ')) {
                const [firstPart, secondPart] = sharedUser.split(' ');
                firstName = firstPart;
                surName = secondPart;
            } else {
                firstName = sharedUser.charAt(0);
            }
            if (!bankTransferOption || !donationAmount || !chequeData.transaction_date || !selectedBank.value) {
                // Toast an error message if any of the fields are missing
                toast.error("Please fill in all required fields.");
                return;
            }

            const bankData = {
                payment_code: id,
                bank_payment_channel: bankTransferOption,
                bank_transaction_reference: chequeData.chequeNumber,
                currency: chequeData.currency,
                transcation_amount: donationAmount,
                transaction_date: chequeData.transaction_date,
                transcation_time: formattedDate + '000000',
                payment_channel: "bank",
                phone_number: phoneNumber,
                status: "pending",
                source: "web",
                first_name: firstName,
                bank: selectedBank.value,
                bill_ref_number: id,

            };

            setPayloading(true)

            serverUrl.post(`/giving/create-ctb-transaction/`, bankData)
                .then((res) => {
                    toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
                    setModalShow(false);
                    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`)
                        .then((res) => {
                            setPledgeStatements(res.data.results[0].pledge_statement)
                            setContributorStatements(res.data.results[0].contributor)
                            setCampaignStatements(res.data.results[0].campaign_data[0])
                            setPayloading(false)
                            setTimeout(() => {
                                if (contributor) {
                                    localStorage.removeItem('contributor')
                                    localStorage.removeItem('contributorName')
                                    localStorage.removeItem('contributorNumber')
                                    navigate(`/shared-pledge/${id}`)
                                }
                                toast.warning('Press Done to go back to Citam App', {
                                    position: 'bottom-center',
                                    className: 'bg-warning text-white',
                                    autoClose: 90000
                                });
                            }, 5000);

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }



    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [copied, setCopied] = useState(false);

    const url = `https://tig.citam.org/#/shared-pledge/${id}`;

    const handleCopyLink = () => {
        const textToCopy = `${url}`;
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setCopied(true);
        toast.success("Linked is copied");
    };

    const websiteUrl = `https://tig.citam.org/#/shared-pledge/${id}`;
    const appUrl = "https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app";

    // const messageLink = `${userPledgeData.campaing_name}\n${userPledgeData.campaing_description}\nMy Pledge:KSH.${userPledgeData.pledge_target}\nRaised Amount:KSH.${userPledgeData.total_pledge_paid}\nTo Assist In My Pledge Click The Link below\n${websiteUrl}`;
    const descriptionNew = 'I welcome you to contribute towards my pledge for CITAM’s Together in Generosity (TIG) initiative. The campaign is to raise funds/resources to facilitate the settling of 23 assemblies that are in tents while others are on leased premises.'

    const messageLink = `${descriptionNew}\nTo Assist In My Pledge Click The Link below\n${websiteUrl}`;


    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        messageLink
    )}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        messageLink
    )}`;

    const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        messageLink
    )}`;

    const instagramLink = "https://www.instagram.com/direct/inbox/";

    function shareFacebook() {
        let url = encodeURIComponent(messageLink);
        let title = "title";
        window.open(
            `http://www.facebook.com/sharer.php?u=${url}`,
            "pop up name",
            "width=600, height=600"
        );
    }

    const handlePlatform = (platform) => {
        var Platform = platform;

        if (platform === "whatsapp") {
            window.open(whatsappLink, "_blank");
        } else if (platform === "facebook") {
            // Handle Facebook logic here
            // ...
        } else if (platform === "gmail") {
            window.open(EmailLink);
        } else if (platform === "x") {
            window.open(twitterLink, "_blank");
        } else if (platform === "instagram") {
            window.open(instagramLink, "_blank");
        } else if (platform === "copy_link") {
            handleCopyLink();
        }

    };

    const [showOptions, setShowOptions] = useState(false);

    // Function to handle showing the options
    const handleShowOptions = () => {
        setShowOptions(true);
    };


    // Inside your component
    const [showModal, setShowModal] = useState(false);

    // Function to handle showing the modal
    const handleShowModalNumber = () => {
        setShowModal(true);
    };
    // JSX code for the options
    const phoneNumberOptions = phoneNumberOther.map((number, index) => (
        <option key={index} value={number.phone_number}>{number.phone_number}</option>
    ));

    // Function to handle adding the number
    let combinedName;
    if (userPledgeData && userPledgeData.shared_user) {
        const splitName = userPledgeData.shared_user.split(' ');
        const firstName = splitName[0];
        let secondNameInitial = null;

        if (splitName.length > 1 && splitName[1].length > 0) {
            secondNameInitial = splitName[1].charAt(0);
        }

        combinedName = `${firstName} ${secondNameInitial !== null ? secondNameInitial + '.' : ''}`;
    } else {
        combinedName = '';
    }

    return (
        <>


            <div className='donation' style={{ paddingTop: "0.5rem" }}>

                <>
                    <h3 style={{ textTransform: "capitalize", placeContent: "center", display: "flex" }}>{userPledgeData.campaing_name}</h3>
                    <p style={{ fontSize: "15px", fontWeight: "700", marginBottom: "0.5rem", marginTop: "0.5rem", textAlign: "left" }}>Select Mode Of Payment</p>

                    <div className='d-flex flex-wrap' style={{ alignItems: "center" }}>

                        <div className='mt-2 d-flex '>
                            <div className={'ModeofPayment' + (selectedPaymentMethod === 'MPESA' ? ' selectedPayment' : '')} onClick={() => handleShowModal('MPESA')}>
                                <Image src={Mpesa} style={{ width: "30%" }} />
                                <p>MPESA</p>
                            </div>

                            <div className={'ModeofPayment' + (selectedPaymentMethod === 'CHEQUE' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CHEQUE')}>
                                <Image src={Cheque} />
                                <p>CHEQUE</p>
                            </div>

                            <div className={'ModeofPayment' + (selectedPaymentMethod === 'CARD' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CARD')}>
                                <Image src={Credit} />
                                <p>CARD</p>
                            </div>

                        </div>
                        {/* 
                            <div style={{ width: "30vw" }} className={'ModeofPayment' + (selectedPaymentMethod === 'PAYPAL' ? ' selectedPayment' : '')} onClick={() => handleShowModal('PAYPAL')}>
                                <Image src={PayPal} style={{ width: "25%" }} />
                                <p>PAYPAL</p>
                            </div> */}

                        <div className='mt-2 d-flex '>

                            <div style={{ width: "45vw" }} className={'ModeofPayment' + (selectedPaymentMethod === 'BANK TRANSFER' ? ' selectedPayment' : '')} onClick={() => handleShowModal('BANK TRANSFER')}>
                                <Image src={Bank} tyle={{ width: "15%" }} />
                                <p>BANK TRANSFER</p>
                            </div>

                        </div>


                    </div>

                    {selectedPaymentMethod === 'MPESA' && (
                        // Content for MPESA
                        <>

                            <div className='donation p-2'>
                                <p style={{ fontSize: "15px", fontWeight: "700", marginBottom: "0.5rem", marginTop: "0.5rem", textAlign: "left" }}>Input Amount <b className='text-danger'>*</b></p>
                                <div className='d-flex' style={{ placeContent: "center", width: "40vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                                    <p className='kshP text-danger' style={{ fontSize: "18px", fontWeight: "300" }}>KSH</p>
                                    <input
                                        type='text'
                                        pattern="[0-9]*"
                                        value={donationAmount ? donationAmount.toLocaleString() : ''}
                                        onChange={(e) => {
                                            const inputVal = e.target.value.replace(/\D/g, '');
                                            setDonationAmount(inputVal);
                                        }}
                                        style={{ paddingLeft: "4rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }}
                                    />

                                </div>

                                <div className='d-flex flex-wrap'>
                                    {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                        <Button
                                            key={index}
                                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                            onClick={() => handleButtonClick(index, amount)}
                                            onChange={handleAmountChange}

                                        >
                                            Ksh. {amount.toLocaleString()}
                                        </Button>
                                    ))}
                                </div>
                                <label className='fw-bold'>M-PESA Phone Number <b className='text-danger'>*</b></label>
                                <input className='input-field fieldData' type='text'
                                    value={phoneNumber}
                                    onChange={handlePhoneNumber}
                                    placeholder='Phone Number' style={{ fontSize: "16px" }}
                                    required
                                />

                            </div>
                        </>
                    )}
                    {selectedPaymentMethod === 'CHEQUE' && (
                        // Content for CHEQUE
                        <>
                            <div className='donation p-0'>
                                <p className='fw-bold' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                <div className='d-flex' style={{ placeContent: "center", width: "50vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                                    <select value={chequeData.currency}
                                        onChange={handlePledgeInput}
                                        className='currency'
                                        style={{ border: "2px solid #9A1712", borderRadius: "10px", height: "5vh", marginTop: "1rem", color: "#9A1712", marginLeft: "-1rem" }}
                                        name='currency'>
                                        <option className='kshP' value='KSH'>KSH</option>
                                        <option className='kshP' value='USD'>USD</option>
                                        <option className='kshP' value='EUR'>EUR</option>
                                        <option className='kshP' value='GBP'>GBP</option>
                                    </select>
                                    <input
                                        type='text'
                                        pattern="[0-9]*"
                                        value={donationAmount ? donationAmount.toLocaleString() : ''}
                                        onChange={(e) => {
                                            const inputVal = e.target.value.replace(/\D/g, '');
                                            setDonationAmount(inputVal);
                                        }}
                                        style={{ paddingLeft: "1rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }}
                                    />


                                </div>

                                <div className='d-flex flex-wrap'>
                                    {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                        <Button
                                            key={index}
                                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                            onClick={() => handleButtonClick(index, amount)}
                                            onChange={handleAmountChange}

                                        >
                                            Ksh. {amount.toLocaleString()}
                                        </Button>
                                    ))}
                                </div>


                            </div>


                            <p className='fw-bold' style={{ fontSize: "12px" }}>Cheque Number <b className='text-danger'>*</b></p>
                            <input
                                className='chequeNumber'
                                style={{ width: "97%", fontSize: "12px", padding: "0.5rem", background: "#e9e5e5db", }}
                                type='text'
                                placeholder='Cheque Number'
                                onChange={handlePledgeInput}
                                name='chequeNumber'
                            />

                            <p className='fw-bold' style={{ fontSize: "12px" }}>Image Of Cheque(optional)</p>
                            <Button style={{ fontSize: "14px", width: "97%", border: "1px dotted black", height: "8vh" }} className='btn btn-sm bg-transparent text-danger' onClick={() => document.getElementById('imageUpload').click()}>
                                <Image src={Shutter} style={{ width: "5%" }} />
                            </Button>

                            <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleChangeImage}
                            />
                            {images && images instanceof File && (
                                <>
                                    <img
                                        src={URL.createObjectURL(images)}
                                        alt=""
                                        className=""
                                        style={{ width: '20vw', height: '20vh', margin: "0 auto", display: "block" }}
                                    />

                                </>
                            )}


                            <div>
                                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                <input
                                    className='chequeNumber'
                                    onChange={handlePledgeInput}
                                    value={chequeData.transaction_date}
                                    type='date'
                                    name='transaction_date'
                                    placeholder='Payment Date'
                                    max={new Date().toISOString().slice(0, 10)}
                                    style={{ width: "35vw", fontSize: "12px", padding: "10px", background: "#d3d3d361" }}
                                    required

                                />

                            </div>

                        </>
                    )}
                    {selectedPaymentMethod === 'CARD' && (
                        // Content for CARD
                        <>
                            <div className='donation p-0'>
                                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                <div className='d-flex' style={{ placeContent: "center", width: "45vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                                    <select value={chequeData.currency}
                                        onChange={handlePledgeInput}
                                        className='currency'
                                        style={{ border: "2px solid #9A1712", borderRadius: "10px", height: "5vh", marginTop: "1rem", color: "#9A1712", marginLeft: "-1rem" }}
                                        name='currency'>
                                        <option className='kshP' value='KSH'>KSH</option>
                                        <option className='kshP' value='USD'>USD</option>
                                        <option className='kshP' value='EUR'>EUR</option>
                                        <option className='kshP' value='GBP'>GBP</option>
                                    </select>
                                    <input
                                        type='text'
                                        pattern="[0-9]*"
                                        value={donationAmount ? donationAmount.toLocaleString() : ''}
                                        onChange={(e) => {
                                            const inputVal = e.target.value.replace(/\D/g, '');
                                            setDonationAmount(inputVal);
                                        }}
                                        style={{ paddingLeft: "1rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }}
                                    />
                                </div>

                                <div className='d-flex flex-wrap'>
                                    {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                        <Button
                                            key={index}
                                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                            onClick={() => handleButtonClick(index, amount)}
                                            onChange={handleAmountChange}

                                        >
                                            Ksh. {amount.toLocaleString()}
                                        </Button>
                                    ))}
                                </div>


                            </div>


                            <p className='fw-bold' style={{ fontSize: "12px" }}>Reference Number <b className='text-danger'>*</b></p>
                            <input
                                className='chequeNumber'
                                style={{ width: "50vw", fontSize: "12px", padding: "0" }}
                                type='text'
                                placeholder='Reference Number'
                                onChange={handlePledgeInput}
                                name='chequeNumber'
                                required
                            />




                            <div>
                                <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                <input
                                    className='chequeNumber'
                                    onChange={handlePledgeInput}
                                    value={chequeData.transaction_date}
                                    type='date'
                                    name='transaction_date'
                                    placeholder='Payment Date'
                                    max={new Date().toISOString().slice(0, 10)}
                                    style={{ width: "100vw", fontSize: "12px", padding: "0" }}
                                    required

                                />

                            </div>

                        </>

                    )}
                    {selectedPaymentMethod === 'PAYPAL' && (
                        // Content for PAYPAL
                        <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>
                    )}
                    {selectedPaymentMethod === 'BANK TRANSFER' && (
                        // Content for BANK TRANSFER
                        <>
                            <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Select type of bank transer <b className='text-danger'>*</b></p>


                            <div className='mt-3'>
                                <select
                                    className='form-select w-50'
                                    value={bankTransferOption}
                                    onChange={(e) => handleBankTransferOptionChange(e.target.value)}
                                    style={{ fontSize: "12px" }}
                                    requ
                                >
                                    <option value='PESALINK'>Pesalink</option>
                                    <option value='EFT'>EFT</option>
                                    <option value='RTGS'>RTGS</option>
                                </select>
                            </div>

                            <>
                                <div className='donation p-0'>
                                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                                    <div className='d-flex' style={{ placeContent: "center", width: "75vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                                        <select value={chequeData.currency}
                                            onChange={handlePledgeInput}
                                            className='currency'
                                            style={{ border: "0", color: "#9A1712" }}
                                            required
                                            name='currency'>
                                            <option className='kshP' value='KSH'>KSH</option>
                                            <option className='kshP' value='USD'>USD</option>
                                            <option className='kshP' value='EUR'>EUR</option>
                                            <option className='kshP' value='GBP'>GBP</option>
                                        </select>

                                        <input
                                            type='text'
                                            pattern="[0-9]*"
                                            value={donationAmount ? donationAmount.toLocaleString() : ''}
                                            onChange={(e) => {
                                                const inputVal = e.target.value.replace(/\D/g, '');
                                                setDonationAmount(inputVal);
                                            }}
                                            style={{ paddingLeft: "1rem", backgroundColor: "transparent", border: "0", outline: "0", width: "60vw" }}
                                        />


                                    </div>

                                    <div className='d-flex flex-wrap'>
                                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                                            <Button
                                                key={index}
                                                className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                                                onClick={() => handleButtonClick(index, amount)}
                                                onChange={handleAmountChange}

                                            >
                                                Ksh. {amount.toLocaleString()}
                                            </Button>
                                        ))}
                                    </div>


                                </div>


                                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Bank <span className='text-danger'>*</span></p>
                                <Select
                                    options={optionList}
                                    placeholder="Select a bank"
                                    value={selectedBank}
                                    onChange={handleSelectChange}
                                    style={{ padding: "0", fontSize: "12px" }}
                                    required
                                />

                                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Transaction Number(Optional)</p>
                                <input
                                    className='chequeNumber'
                                    style={{ width: "35vw", padding: "0", fontSize: "12px" }}
                                    type='text'
                                    placeholder='Transaction Number'
                                    onChange={handlePledgeInput}
                                    value={chequeData.chequeNumber}

                                    name='chequeNumber'
                                />

                                <div>
                                    <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                                    <input
                                        className='chequeNumber'
                                        onChange={handlePledgeInput}
                                        value={chequeData.transaction_date}
                                        type='date'
                                        name='transaction_date'
                                        placeholder='Payment Date'
                                        max={new Date().toISOString().slice(0, 10)}
                                        style={{ padding: "0", fontSize: "12px" }}
                                    />

                                </div>
                                            
                            </>
                        </>

                    )}


                    {selectedPaymentMethod === 'PAYPAL' || selectedPaymentMethod === null ? null :
                        selectedPaymentMethod && (
                            <>
                                <p onClick={handleShow} className='text-info text-center mt-3' style={{ textTransform: "capitalize", fontSize: "14px", paddingInline: "1rem" }}>
                                    Tap to share a link to another person to contribute for you
                                </p>
                                {selectedPaymentMethod === 'MPESA' ? (
                                    <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                                        <p className='text-danger fw-bold mt-2'>Alternative M-PESA Payment Steps:</p>
                                        <ol>
                                            <li>Launch SIM Toolkit or M-PESA App</li>
                                            <li>Select Paybill</li>
                                            <li>Enter the Business Number: <b>5141411</b></li>
                                            <li>Enter the Account Number: <b>{id}</b></li>
                                            <li>Enter Amount and complete the transaction using your M-PESA PIN</li>
                                        </ol>
                                    </div>
                                ) : selectedPaymentMethod === 'BANK TRANSFER' || selectedPaymentMethod === 'CHEQUE' ?
                                    <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                                        <p className='text-danger fw-bold mt-2'>Alternative Bank transfer Payment Steps:</p>
                                        <ol>
                                            <li>Bank: Stanbic Bank</li>
                                            <li>Branch: Westlands</li>
                                            <li>Account Name: Christ Is The Answer Ministries</li>
                                            <li>Account Number: 0100000496426</li>
                                            <li>SWIFT CODE: <b>SBICKENX</b></li>
                                        </ol>
                                    </div>
                                    :


                                    (
                                        <>
                                        </>
                                    )}
                            </>
                        )
                    }


                </>




                {/* modal for sharing */}
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger fw-bold m-auto'>SHARE THIS PLEDGE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("whatsapp")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "98%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Whatsapp}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on WhatsApp</p>
                                </Col>
                            </div>

                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("facebook")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                    onClick={() => shareFacebook()}
                                >
                                    <Image
                                        src={Facebook}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Facebook</p>
                                </Col>
                            </div>
                        </Row>

                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("gmail")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Mail}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Gmail</p>
                                </Col>
                            </div>

                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("x")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Twitter}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Twitter</p>
                                </Col>
                            </div>
                        </Row>

                        <Row className="d-flex">
                            <div
                                className="text-decoration-none text-dark w-50"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePlatform("instagram")}
                            >
                                <Col
                                    md={6}
                                    className="d-flex shareButtons"
                                    style={{
                                        border: "1px solid #9a1712",
                                        width: "91%",
                                        marginInline: "0.5rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                >
                                    <Image
                                        src={Instagram}
                                        className="mb-2 me-2 mt-2 ms-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Share on Instagram</p>
                                </Col>
                            </div>

                            <Col
                                className='shareButtons'
                                md={6}
                                style={{
                                    border: "1px solid #9a1712",
                                    width: "44%",
                                    marginInline: "1.1rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                                onClick={() => handlePlatform("copy_link")}
                            >
                                <div className="d-flex">
                                    <Image
                                        src={Paperclip}
                                        className="mb-2 me-2 mt-2"
                                        style={{
                                            borderRadius: "10px",
                                            width: "auto",
                                            height: "5vh",
                                        }}
                                    />
                                    <p className="mt-3">Copy the link </p>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className='footerButton'>
                    {payLoading ?
                        <Button className='btn-danger'>
                            <Spinner />
                        </Button>
                        :
                        contributor ?
                            < Button className='btn-danger' onClick={handleRedeemAdd}>Redeem for {combinedName}</Button>

                            :
                            < Button className='btn-danger' onClick={handleRedeemAdd}>Redeem</Button>


                    }
                </div>


            </div >
        </>
    );
}

export default DonationShared;
