import React, { useState, useEffect, useCallback } from 'react'
import { Button, FormControl, Dropdown, Image, Modal, Form, FormSelect } from 'react-bootstrap'
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Select from 'react-select'
import Calendar from '../../assets/img/calendar.png'
import PhoneNumberValidation from '../components/PhoneNumberValidation';

function MobileProfile() {
    const navigate = useNavigate()

    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [userData, setUserData] = useState({});
    const [editProfile, setEditProfile] = useState(false);
    const [editOtherDetails, setEditOtherDetails] = useState(false);
    const [profileData, setprofileData] = useState([])
    const [profilePhoneNumber, setProfilePhoneNumber] = useState([])
    const [userProfileName, setUserProfileName] = useState('')
    const handleLogout = () => {
        // Clear the tokens from local storage and redirect to the login page
        localStorage.removeItem("authTokens");
        toast.success('Successfull logged out !')
        navigate("/login");
    }
    // switching account functionalities

    const Cooporates = localStorage.getItem('coporates')

    const previousTokens = localStorage.getItem('authTokens')

    const addAccount = () => {
        navigate('/register/corporate')
        localStorage.removeItem("authTokens");
        localStorage.setItem("previousTokens", previousTokens)

        if (userData.account_type === 'individual' || userData.account_type === 'family') {
            localStorage.setItem('newAccount', 'corporate')
            localStorage.setItem('userLinked', userData.id)


        }
        else {
            localStorage.setItem('newAccount', 'individual')
            localStorage.setItem('corporateLinked', userData.id)


        }

        toast.success('Kindly create the account')
    };

    const switchAccount = () => {
        // Retrieve authTokens and previousTokens from localStorage
        const authTokens = localStorage.getItem('authTokens');
        const previousTokens = localStorage.getItem('previousTokens');

        // Set previousTokens with the value of authTokens
        localStorage.setItem('previousTokens', authTokens);

        // Set authTokens with the value of previousTokens
        localStorage.setItem('authTokens', previousTokens);

        // Display toast message indicating success
        toast.success('Successfully switched account');

        // Reload the page
        window.location.reload();
    };


    const [linkedAccounts, setLinkedAccounts] = useState({})
    const [linkedAccountsÇounts, setLinkedAccountsÇounts] = useState()

    const churchResults = [

        {
            "id": 5,
            "name": "CITAM Athi River",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 6,
            "name": "CITAM Buruburu",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 8,
            "name": "CITAM Eldoret",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 9,
            "name": "CITAM Embakasi",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 10,
            "name": "CITAM Embu",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 11,
            "name": "CITAM Kakamega",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 12,
            "name": "CITAM Kangundo Road",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 13,
            "name": "CITAM Kapsabet",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 14,
            "name": "CITAM Karen",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 15,
            "name": "CITAM Kericho",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 16,
            "name": "CITAM Kiambu Road",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 17,
            "name": "CITAM Kiserian",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 18,
            "name": "CITAM Kisii",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 19,
            "name": "CITAM Kisumu",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 20,
            "name": "CITAM Kitengela",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 21,
            "name": "CITAM Machakos",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 22,
            "name": "CITAM Makueni",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 23,
            "name": "CITAM Meru",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 24,
            "name": "CITAM Mombasa",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 25,
            "name": "CITAM Naivasha",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 26,
            "name": "CITAM Nakuru",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 27,
            "name": "CITAM Ngong",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 28,
            "name": "CITAM Nyeri",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 29,
            "name": "CITAM Rongai",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 30,
            "name": "CITAM Ruiru",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 31,
            "name": "CITAM Syokimau",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 32,
            "name": "CITAM Thika Road",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 33,
            "name": "CITAM Thika Town",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 34,
            "name": "CITAM Valley Road",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 35,
            "name": "CITAM Woodley",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 36,
            "name": "CITAM Archer's Post",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 37,
            "name": "CITAM Gororukesa",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 38,
            "name": "CITAM Mataarba",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 39,
            "name": "CITAM Kargi",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 40,
            "name": "CITAM Olturot",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 41,
            "name": "CITAM Loiyangalani",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 42,
            "name": "CITAM Namoruputh",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 43,
            "name": "CITAM Parklands",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        },
        {
            "id": 44,
            "name": "CITAM Clay City",
            "query": "",
            "logo_path": "https://citamfundraiser.net/media/default_church.png"
        }
    ]
    const [selectedOptions, setSelectedOptions] = useState(null);
    const handleSelectChange = (selectedOption) => {
        setSelectedOptions(selectedOption);
    };



    const [images, setImages] = useState([]);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropChange = useCallback((crop) => {
        setCrop(crop);
    }, []);

    const handleZoomChange = useCallback((zoom) => {
        setZoom(zoom);
    }, []);

    const handleChangeImage = (e) => {

        const file = e.target.files[0];

        setImages(file);
    };
    const [cropImage, setCropImage] = useState([]);
    const handleCropImage = async (e) => {

        try {
            const croppedImage = await getCroppedImg(images, croppedAreaPixels);
            setCropImage(croppedImage);
            console.log(croppedImage)
            setImages(null)
        } catch (error) {
            console.error('Error cropping image:', error);
        }
    };

    useEffect(() => {
        serverUrl.get(`/user/user-details/?id=${userId}`, config)
            .then((res) => {
                setUserData(res.data.results[0])
                if (res.data.results[0].account_type === 'corporate') {
                    serverUrl.get(`/user/corporates?corporate_user=${res.data.results[0].id}`, config)
                        .then((res) => {
                            setLinkedAccounts(res.data.results[0])
                            setLinkedAccountsÇounts(res.data.count)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    serverUrl.get(`/user/corporates?user=${res.data.results[0].id}`, config)
                        .then((res) => {
                            setLinkedAccounts(res.data.results[0])
                            setLinkedAccountsÇounts(res.data.count)


                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [userId])

    const [userChurch, setUserChurch] = useState({});
    const [optionList, setOptionList] = useState(null);

    useEffect(() => {
        serverUrl.get(`/user/user-details/?id=${userId}`, config)
            .then((res) => {
                setUserData(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
        // get the profile data 
        serverUrl.get(`/user/profile/?user=${userId}`, config)
            .then((res) => {
                setprofileData(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the user other phone numbers
        serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
            .then((res) => {
                setProfilePhoneNumber(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the church for that user
        serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
            .then((res) => {
                setUserChurch(res.data.results[0])
                setIsLoading(false)

            })
            .catch((error) => {
                console.log(error)
            })
        serverUrl.get(`/churches/church-logo-name`)
            .then((res) => {
                // Filter out churches with name 'hq'
                const filteredResults = res.data.results.filter(church => church.name !== 'CITAM HQ');

                // Map the filtered results to options
                const options = filteredResults.map(church => ({
                    value: church.id,
                    label: church.name
                }));

                // Set the options list
                setOptionList(options);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [userId])


    const [userEditData, setUserEditData] = useState({
        full_name: "",
        phone_number: "",
        email: "",
        id_number: "",
        idnumber: "",
        dob: "",
    })
    const [selectedGender, setSelectedGender] = useState(userData.gender)

    useEffect(() => {
        setUserEditData({
            full_name: userData.full_name || '',
            phone_number: userData.phone_number || '',
            email: userData.email || '',
            id_number: userData.id_number,
            dob: userData.dob || '',
        })
    }, [userData])
    const handleIdNumberClick = () => {
        toast.error('You cannot edit your ID number. Please contact your admin for assistance.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const [showAddAccounts, setShowAddAccounts] = useState(false);
    const [selectedUserProfileRelation, setSelectedUserProfileRelation] = useState('')
    const handleUserProfileRelationChange = (event) => {
        setSelectedUserProfileRelation(event.target.value);
    };

    const handleEditFunction = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const data = {
            full_name: userEditData.full_name,
            phone_number: userEditData.phone_number,
            email: userEditData.email,
            gender: selectedGender,
        };
        if (userEditData.idnumber) {
            data.id_number = userEditData.idnumber
        }
        if (userEditData.dob) {
            data.dob = userEditData.dob
        }

        serverUrl.patch(`/user/edit-user/${userId}`, data, config)
            .then((res) => {
                toast.success('Successfully changed the data ')
                setEditProfile(false)
                serverUrl.get(`/user/user-details/?id=${userId}`, config)
                    .then((res) => {
                        setUserData(res.data.results[0])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };




    const [showEditAccounts, setShowEditAccounts] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null)
    const handleEditAccountClose = () => {
        setShowEditAccounts(false)
    }
    const handleEditAccountShow = (id) => {
        setShowEditAccounts(true)
        setSelectedProfile(id)
    }

    const [profileEdit, setProfileDataEdit] = useState({
        profile_name: '',
        relation: '',
    });

    useEffect(() => {
        if (selectedProfile) {

            setProfileDataEdit({
                profile_name: selectedProfile.profile_name || '',
                relation: selectedProfile.relation || '',
            });
        }

    }, [selectedProfile]);

    const handleChange = (e) => {

        const { name, value } = e.target;
        setProfileDataEdit(prevData => ({
            ...prevData,
            [name]: value
        }));
        setUserEditData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setEditedPhoneNumber(prevData => ({
            ...prevData,
            [name]: value
        }));


    };



    const [showDeleteAccounts, setShowDeleteAccounts] = useState(false);
    const handleDeleteAccountClose = () => {
        setShowDeleteAccounts(false)
    }

    const handleDeleteAccountShow = (profile) => {
        setShowDeleteAccounts(true)
        setSelectedProfile(profile)
    }
    const handleEditProfile = () => {
        setEditProfile(!editProfile);
    };

    const handleEditOtherDetails = () => {
        setEditOtherDetails(!editOtherDetails);
    };



    const handleChangeUser = (e) => {

        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
        // Check if the field being edited is the ID number
        if (name === 'id_number' && editOtherDetails) {
            // Display toast error message
            toast.error('You need to contact the admin to change your ID number.');
            return;
        }
    };
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };


    const [showAddModalPhone, setShowAddModalPhone] = useState(false);
    const [addedPhone, setAddedPhone] = useState('')

    const handleAddPhoneNumber = () => {
        const data = {
            user: userId,
            phone_number: addedPhone,
            country_name: 'Kenya',
            country_code: '+254'
        }
        serverUrl.post(`/user/user-linked-phone-numbers/`, data, config)
            .then((res) => {
                serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
                    .then((res) => {
                        toast.success('Succesfully added the phone number')
                        setProfilePhoneNumber(res.data.results)
                        setIsLoading(false)
                        setShowAddModalPhone(false)

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    const [showEditPhoneModal, setShowEditPhoneModal] = useState(false);

    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
    const handleEditPhoneNumberShow = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        setShowEditPhoneModal(true);
    };


    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedProfileEdit, setSelectedProfileEdit] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedRelation, setEditedRelation] = useState('');
    const [editedPhoneNumber, setEditedPhoneNumber] = useState({
        phone_number: '',
    });
    useEffect(() => {
        if (selectedPhoneNumber) {

            setEditedPhoneNumber({
                phone_number: selectedPhoneNumber.phone_number || '',
            });
        }

    }, [selectedPhoneNumber]);
    const handleEdit = (profile) => {
        setSelectedProfileEdit(profile);
        setEditedName(profile.profile_name);
        setEditedRelation(profile.relation);
        setShowEditModal(true);
    };
    const handleDeletePhoneNumberShow = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        setShowDeleteConfirmation(true);
    };
    const handleDelete = (profile) => {
        serverUrl.delete(`/user/profile/${profile.id}/`, config)
            .then((res) => {
                toast.success('Succesfully deleted the profile')
                serverUrl.get(`/user/profile/?user=${userId}`, config)
                    .then((res) => {
                        setprofileData(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
        // Implement confirmation modal or directly delete the profile
    };

    const handleEditSubmit = () => {
        const data = {
            user: userId,
            profile_name: editedName,
            relation: editedRelation,
        }
        serverUrl.patch(`/user/profile/${selectedProfileEdit.id}/`, data, config)
            .then((res) => {
                setShowEditModal(false);
                toast.success('Succesfully edited the profile')
                serverUrl.get(`/user/profile/?user=${userId}`, config)
                    .then((res) => {
                        setprofileData(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const handleEditNameChange = (e) => {
        setEditedName(e.target.value);
    };
    const phoneStyles = { marginBottom: "10px", border: "1px solid #9A1712", borderRadius: "10px" }

    const handleEditRelationChange = (e) => {
        setEditedRelation(e.target.value);
    };

    const [showAccounts, setShowAccounts] = useState(false);

    const handleAccountClose = () => setShowAccounts(false);
    const handleAccountShow = () => setShowAccounts(true);
    const handleAddAccountClose = () => {
        setShowAddAccounts(false)
        setShowAccounts(true)
    }

    const handleAddAccountShow = () => {
        setShowAddAccounts(true)
        setShowAccounts(false)
    }


    const handleEditPhoneNumber = () => {
        const data = {
            phone_number: editedPhoneNumber.phone_number
        }
        serverUrl.patch(`/user/user-linked-phone-numbers/${selectedPhoneNumber.id}/`, data, config)
            .then((res) => {
                serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
                    .then((res) => {
                        setProfilePhoneNumber(res.data.results)
                        toast.success('Succesfully edited the phone number')
                        setShowEditPhoneModal(false);

                    })
                    .catch((error) => {
                        console.log(error)
                        toast.error('Duplicate Phone Number')
                    })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


    const handleDeletePhoneNumber = () => {
        serverUrl.delete(`/user/user-linked-phone-numbers/${selectedPhoneNumber.id}`, config)
            .then((res) => {
                serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
                    .then((res) => {
                        setProfilePhoneNumber(res.data.results)
                        setShowDeleteConfirmation(false);
                        toast.success('Succesfully deleted the phone number')


                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };
    function handleAddNewProfileAccount(e) {
        e.preventDefault();
        // check if user has entered phone number
        const phoneNumber = localStorage.getItem('number')
        const country = localStorage.getItem('country')
        const countryCode = localStorage.getItem('countryCode')

        if (phoneNumber) {
            const dataPhone = {
                user: userId,
                phone_number: phoneNumber,
                country_code: countryCode,
                country_name: country,
            }
            // post the data and get the id
            serverUrl.post('/user/user-linked-phone-numbers/', dataPhone, config)
                .then((res) => {
                    localStorage.removeItem('number')
                    localStorage.removeItem('country')
                    localStorage.removeItem('countryCode')
                    // post the data now
                    const formData = new FormData();
                    // Append user, relation, and profile_name fields
                    formData.append('user', userId);
                    formData.append('relation', selectedUserProfileRelation);
                    formData.append('profile_name', userProfileName);
                    formData.append('phone_number', res.data.id);


                    if (cropImage instanceof Blob || cropImage instanceof File) {
                        // Get the current date and time
                        const currentDate = new Date();
                        // Construct a unique file name using the current date and time
                        const fileName = `cropped_image_${currentDate.getTime()}.png`;

                        // Append the cropped image to FormData with the constructed file name
                        formData.append('image', cropImage, fileName);
                    }


                    serverUrl.post(`/user/profile/`, formData, config)
                        .then((res) => {
                            toast.success('Succesfully added the Account')
                            setImages(null);
                            setSelectedUserProfileRelation(null);
                            setUserProfileName(null);
                            setShowAddAccounts(false)
                            handleChangeAccountType('other')
                            setShowAccounts(true)

                            // get the profiles for the current user
                            serverUrl.get(`/user/profile/?user=${userId}`, config)
                                .then((res) => {
                                    setUserProfile(res.data.results)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error)
                        })


                })
                .catch((error) => {
                    console.log(error)
                })

        }

        else {
            // Create a new FormData object
            const formData = new FormData();
            // Append user, relation, and profile_name fields
            formData.append('user', userId);
            formData.append('relation', selectedUserProfileRelation);
            formData.append('profile_name', userProfileName);
            if (cropImage instanceof Blob || cropImage instanceof File) {
                // Get the current date and time
                const currentDate = new Date();
                // Construct a unique file name using the current date and time
                const fileName = `cropped_image_${currentDate.getTime()}.png`;

                // Append the cropped image to FormData with the constructed file name
                formData.append('image', cropImage, fileName);
            }

            serverUrl.post(`/user/profile/`, formData, config)
                .then((res) => {
                    toast.success('Succesfully added the Account')
                    setImages(null);
                    setSelectedUserProfileRelation(null);
                    setUserProfileName(null);
                    setShowAddAccounts(false)
                    handleChangeAccountType('other')
                    setShowAccounts(true)

                    // get the profiles for the current user
                    serverUrl.get(`/user/profile/?user=${userId}`, config)
                        .then((res) => {
                            setUserProfile(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error)
                })
        }


    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleDeleteAccount = () => {
        const data = {
            is_active: false
        }
        serverUrl.patch(`/user/edit-user/${userId}`, data, config)
            .then((res) => {
                toast.success('You account will be deleted in 14days')
                navigate('/register')
                localStorage.removeItem("authTokens");

            })
            .catch((error) => {
                console.log(error)
            })
    };

    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleChangeAssembly = () => {

        const data = {
            church: selectedOptions.value
        }

        if (userChurch) {
            serverUrl.patch(`/churches/churchuser/${userChurch.id}`, data, config)
                .then((res) => {
                    setShowModal(false);
                    setSelectedOptions(null)

                    serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
                        .then((res) => {
                            setUserChurch(res.data.results[0])
                            setIsLoading(false)
                            toast.success('Succesfully switched your church assembly')

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else {
            const formData = new FormData
            formData.append('church', selectedOptions.value)
            formData.append('user', userId)


            serverUrl.post(`/churches/create-church-user/`, formData)
                .then((res) => {
                    setShowModal(false);
                    setSelectedOptions(null)

                    serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
                        .then((res) => {
                            setUserChurch(res.data.results[0])
                            setIsLoading(false)
                            toast.success('Succesfully added your church assembly')

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    };
    const todayDate = new Date().toISOString().split('T')[0];
    const [showPhoneModal, setShowPhoneModal] = useState(false);

    const handlePhoneButtonClick = () => {
        setShowPhoneModal(true);
    };

    const handleClosePhoneModal = () => {
        setShowPhoneModal(false);
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className='profileUser'>
            <div className='d-flex justify-content-between' style={{ marginTop: "-1rem" }}>
                <p onClick={goBack} style={{}}>
                    <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem" }}></i>
                </p>
                <h2 style={{ margin: "0 auto", alignContent: "center", fontWeight: "800", fontSize: "25px" }}>My Profile</h2>
            </div>


            <div className='centerData'>
                <Image src={userData.profile_picture} className="rounded-circle" />
                <>
                    <h6 style={{ color: "grey", fontSize: "25px", marginTop: "15px" }}>{userData.full_name}</h6>
                    <span style={{ fontSize: "15px" }} className=" d-block mb-2">{userData.email}</span>
                    <span style={{ fontSize: "15px" }} className=" d-block mb-2">{userData.phone_number}</span>

                </>
                <Button className="btn btn-sm btn-danger text-white" style={{ borderRadius: "8px", fontWeight: "700" }} onClick={handleButtonClick}>
                    {userChurch ? userChurch.church_name : "Guest"}
                </Button>

                <div className='ButtonEditPhones mt-2'>
                    <Button className="btn btn-sm bg-transparent text-dark" onClick={() => setEditProfile(true)} style={{ width: "20vw" }}>
                        EDIT
                    </Button>

                    <Button className="btn btn-sm bg-transparent text-dark" onClick={handlePhoneButtonClick}>Phone Numbers</Button>

                </div>
                <Modal show={showPhoneModal} onHide={handleClosePhoneModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button className='btn d-flex ms-auto mb-3 btn-danger btn-sm text-white' onClick={() => setShowAddModalPhone(true)}>Add Phone Number</Button>

                        {profilePhoneNumber.length === 0 ?
                            <p>No phone numbers to display add one</p>
                            :
                            profilePhoneNumber.map((phoneNumber) => (
                                <div className='accountsData'>
                                    <div className='accountDetails'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <div className='text-start mt-1 ms-1'>
                                                    <p className='text-muted'>{phoneNumber.phone_number}</p>
                                                </div>
                                            </div>
                                            <Dropdown className='bg-transparent' >
                                                <Dropdown.Toggle className='border-0 bg-transaprent text-dark' variant="transparent" id="dropdown-basic">
                                                    <i className="bi bi-three-dots-vertical"></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleEditPhoneNumberShow(phoneNumber)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleDeletePhoneNumberShow(phoneNumber)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>

                                    </div>

                                </div>
                            ))

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosePhoneModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className='d-flex' style={{ placeContent: "center" }}>
                    <p className='mt-2 text-danger'>
                        <Link className='text-decoration-none text-danger' to='/forgot-password'>Change Password</Link>
                    </p>
                    <p className='mt-2 text-danger'>|</p>
                    <p className='mt-2 text-danger' onClick={() => setShowDeleteModal(true)}>Delete My Account</p>
                </div>

            </div>
            <div className='d-flex justify-content-between mb-2 px-1'>
                <h3 style={{ fontSize: "13px", fontWeight: "800", alignSelf: "center" }}>My Accounts</h3>
                <Button className='btn bg-transparent border-danger text-danger btn-sm ' onClick={handleAddAccountShow}> Add Account</Button>
            </div>
            <div style={{ marginBottom: "5rem" }}>

                {profileData.map((profile) => (
                    <div className='accountsData'>

                        <div className='accountDetails'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex'>
                                    <Image src={profile.image} className="rounded-circle" style={{ height: "7vh", width: "auto" }} />
                                    <div className='text-start mt-1 ms-1'>
                                        <p className='fw-bold' style={{ marginBottom: "0" }}>{profile.profile_name}</p>
                                        <p className='text-muted'>{profile.relation}</p>
                                    </div>
                                </div>
                                <Dropdown >
                                    <Dropdown.Toggle className='border-0 bg-transaprent text-dark' variant="secondary" id="dropdown-basic">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleEdit(profile)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDelete(profile)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>

                    </div>
                ))}
            </div>

            <div className='profileBottomButtons'>
                <Button className='btn btn-dark text-white' onClick={handleLogout}>LOGOUT</Button>
                {Cooporates ?

                    <>

                    </>

                    :
                    <>
                        {linkedAccountsÇounts > 0 ?
                            <>
                                {userData && (
                                    userData.account_type === "individual" || userData.account_type === "family" ?
                                        <Button className='btn btn-dark text-uppercase btn-sm text-white' onClick={switchAccount}>Switch to Corporate</Button>
                                        :
                                        <Button className='btn btn-dark text-uppercase btn-sm text-white' onClick={switchAccount}>Switch to Individual</Button>
                                )

                                }
                            </>
                            :
                            <>
                                {userData && (
                                    userData.account_type === "individual" || userData.account_type === "family" ?
                                        <Button className="btn btn-sm btn-dark text-uppercase text-white border-0" onClick={addAccount}>Switch to Corporate</Button>
                                        :
                                        <Button className="btn btn-sm btn-dark text-uppercase text-white border-0" onClick={addAccount}>Switch to Individual</Button>
                                )
                                }
                            </>
                        }


                    </>
                }




            </div>
            {/* add phone */}
            <Modal show={showAddModalPhone} onHide={() => setShowAddModalPhone(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Phone Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        value={addedPhone}
                        type='text'
                        onChange={(e) => setAddedPhone(e.target.value)}
                        className="input-field"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => setShowAddModalPhone(false)}>Close</Button>
                    <Button variant="danger" onClick={handleAddPhoneNumber}>Save</Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Phone Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the phone number?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeletePhoneNumber}>Delete</Button>
                </Modal.Footer>
            </Modal>

            {/* edit modal */}
            <Modal show={editProfile} onHide={() => setEditProfile(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger fw-bold m-auto'>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditFunction} className='formDataProfile'>
                        <label>Full Name</label>
                        <FormControl
                            type='text'
                            name='full_name'
                            onChange={handleChange}
                            value={userEditData.full_name} />

                        <label>Email</label>
                        <FormControl
                            type='email'
                            name='email'
                            onChange={handleChange}
                            value={userEditData.email} />

                        <label>Id Number</label>
                        {userEditData.id_number === null || userEditData.idnumber ? (
                            <FormControl
                                type='text'
                                name='idnumber'
                                placeholder='Id number is null add one'
                                onChange={handleChange}
                                value={userEditData.idnumber}
                            />
                        ) : (
                            <FormControl
                                type='text'
                                name='id_number'
                                onClick={handleIdNumberClick}
                                readOnly
                                value={userEditData.id_number}
                            />
                        )}

                        <label>Phone Number</label>
                        <FormControl
                            type='text'
                            name='phone_number'
                            onChange={handleChange}
                            value={userEditData.phone_number} />

                        <label>Date of Birth</label>
                        <div className='border-danger input-container' style={{ width: "100%", borderRadius: "6px", height: "4vh" }}>
                            <Image
                                src={Calendar}
                                alt="Calendar"
                                style={{ width: '5%', marginRight: '0.5rem', marginLeft: '0.3rem', cursor: 'pointer' }}
                            >
                            </Image>


                            {userEditData.dob ? (
                                <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1'>{userEditData.dob}</span>
                            ) : (
                                <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1 p-2' >Not Available</span>
                            )}
                            <input
                                type='date'
                                name='dob'
                                onChange={handleChange}
                                max={todayDate}
                                value={userEditData.dob}

                            />


                        </div>

                        <label>Gender</label>
                        <div className='d-flex justify-content-around'>
                            <Button
                                style={{ fontSize: "10px" }}
                                className={`btn btn-sm bg-transparent ${selectedGender === 'male' ? 'border-danger text-danger' : selectedGender === 'female' ? 'border-secondary text-secondary' : 'border-secondary text-secondary'}`}
                                value={selectedGender}
                                onClick={() => setSelectedGender('male')}
                            >
                                <b>Male</b>
                            </Button>
                            <Button
                                style={{ fontSize: "10px" }}
                                className={`btn btn-sm bg-transparent ${selectedGender === 'female' ? 'border-danger text-danger' : selectedGender === 'male' ? 'border-secondary text-secondary' : 'border-secondary text-secondary'}`}
                                value={selectedGender}
                                onClick={() => setSelectedGender('female')}
                            >
                                <b>Female</b>
                            </Button>
                        </div>


                        <div className='d-flex justify-content-between mt-2'>
                            <Button type='button' className='btn btn-sm bg-transparent border-dark me-3 text-dark' onClick={() => setEditProfile(false)}>Cancel</Button>
                            <Button type='submit' className='btn btn-sm bg-danger border-danger text-white'>Update</Button>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>


            {/* edit phone */}
            <Modal show={showEditPhoneModal} onHide={() => setShowEditPhoneModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Phone Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Display selected phone number for editing */}
                    <input
                        value={editedPhoneNumber.phone_number}
                        type='text'
                        onChange={handleChange}
                        name='phone_number'
                        className="input-field"
                    />
                    {/* Implement input fields for editing */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => setShowEditModal(false)}>Close</Button>
                    <Button variant="danger" onClick={handleEditPhoneNumber}>Save</Button>
                </Modal.Footer>
            </Modal>


            {/* edit account */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label style={{ fontWeight: "600", fontSize: "11px" }}>Profile Name</label>
                    <FormControl
                        style={{ backgroundColor: "lightgray", fontSize: "10px", fontWeight: "500" }}
                        type='text'
                        value={editedName}
                        onChange={handleEditNameChange}
                        placeholder="Name"
                    />
                    <label style={{ fontWeight: "600", fontSize: "11px" }}>Profile Relation</label>
                    <FormControl
                        style={{ backgroundColor: "lightgray", fontSize: "10px", fontWeight: "500" }}
                        as="select"
                        value={editedRelation}
                        onChange={handleEditRelationChange}
                        placeholder="Relation"
                    >
                        <option value="child">Child</option>
                        <option value='spouse'>Spouse</option>
                        <option value='other'>Other</option>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-sm btn-dark text-white' variant="" onClick={() => setShowEditModal(false)}>Close</Button>
                    <Button className='btn btn-sm btn-danger text-white' variant="" onClick={handleEditSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>


            {/* modal to add account */}
            <Modal show={showAddAccounts} onHide={handleAddAccountClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger fw-bold m-auto'>Add Account</Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ height: "fit-content" }}>
                    <Form className='makePledgeForm' onSubmit={handleAddNewProfileAccount}>
                        <input
                            type='text'
                            placeholder='Enter Name'
                            className='input-field'
                            onChange={(e) => setUserProfileName(e.target.value)}
                            style={{ fontSize: "14px" }}
                        />
                        <FormSelect onChange={handleUserProfileRelationChange} style={{ fontSize: "14px" }}
                        >
                            <option selected disabled value=''>Select relation</option>
                            <option value='child'>Child</option>
                            <option value='spouse'>Spouse</option>
                            <option value='other'>Other</option>
                        </FormSelect>

                        <label style={{ fontSize: "10px" }}>Phone Number (Optional)</label>
                        <PhoneNumberValidation style={phoneStyles} placeholder='Enter Phone Number (Optional)' />

                        <Button style={{ fontSize: '14px' }} className="btn btn-sm bg-transparent text-danger border-danger" onClick={() => document.getElementById('imageUpload').click()}>Select Photo</Button>
                        <br />
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleChangeImage}
                        />
                        {images && images instanceof File && (
                            <div>
                                <Cropper
                                    image={URL.createObjectURL(images)}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    onCropChange={handleCropChange}
                                    onZoomChange={handleZoomChange}
                                    onCropComplete={onCropComplete}
                                />
                            </div>
                        )}
                        {cropImage instanceof Blob && (
                            <div>
                                <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
                            </div>
                        )}





                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    {images && images instanceof File ? (

                        <p onClick={handleCropImage}>Crop Image</p>
                    ) : (
                        <>
                            <p className='text-dark' onClick={handleAddAccountClose}>Close</p>
                            <p className='text-danger' onClick={handleAddNewProfileAccount}>Save</p>
                        </>
                    )}


                </Modal.Footer>

            </Modal>

            {/* delete the user account */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your account? This action is irreversible.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteAccount}>
                        Yes, Delete My Account
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* change user assembly */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Switch Assembly</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add any additional message or content here */}
                    <Select
                        options={optionList}
                        placeholder="Select assembly"
                        value={selectedOptions}
                        onChange={handleSelectChange}
                        className='church-select'
                        onClick={() => handleChangeGuest('member')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleChangeAssembly}>
                        Yes, Switch Assembly
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MobileProfile