import React from 'react';
import { Modal, Badge, Image } from 'react-bootstrap';
import '../../assets/scss/BadeModal.scss';

const BadgeModal = ({ show, onHide, color, badgeText, badgeImage, badgeTitle, badgeDescription }) => {
    const modalStyle = {
        border: `2px solid ${color}`,
    };

    // Highlighted description with bold/colored words and titles
    const highlightedDescription = badgeDescription?.split('~').map((line, index) => (
        <div key={index}>
            {/* {index === 0 ? (
                <h3 style={{  }}>
                    {line.split(' ').map((word, idx) =>
                        word.startsWith('*')
                            ? <span key={idx} style={{  fontWeight: '700' }}>{word.slice(1)} </span>
                            : `${word} `
                    )}
                </h3>
            ) : (
                <div>
                    {line.split(' ').map((word, idx) => {
                        if (word.startsWith('*')) {
                            return <span key={idx} style={{  fontWeight: '700' }}>{word.slice(1)} </span>;
                        } else if (word.startsWith('#') && word.endsWith('#')) {
                            return <h3 key={idx} style={{  display: 'inline', margin: '0', fontWeight: '700' }}>{word.slice(1, -1)} </h3>;
                        } else {
                            return `${word} `;
                        }
                    })}
                </div>
            )} */}
             <div>
                    {line.split(' ').map((word, idx) => {
                        if (word.startsWith('*')) {
                            return <span key={idx} style={{  fontWeight: '700' }}>{word.slice(1)} </span>;
                        } else if (word.startsWith('#') && word.endsWith('#')) {
                            return <h3 key={idx} style={{  display: 'inline', margin: '0', fontWeight: '700' }}>{word.slice(1, -1)} </h3>;
                        } else {
                            return `${word} `;
                        }
                    })}
                </div>
            {index < badgeDescription.split('~').length - 1 && <><br /></>}
        </div>
    ));

    return (
        <Modal show={show} style={{ '--badge-color': color }} onHide={onHide} centered className='badgeModal'>
            <Modal.Header closeButton />
            <Modal.Body>
                <div style={{ textAlign: 'center' }}>
                    <Image src={badgeImage} alt={badgeText} style={{ width: '25%' }} />
                    <br />
                    <Badge
                        className="custom-badge"
                        style={{ '--badge-color': color }}
                    >
                        {badgeText}
                    </Badge>

                    {/* Adding the badgeTitle */}
                    <h2 style={{ color, marginTop: '10px', fontWeight: '700' }}>{badgeTitle}</h2>

                    <p style={{ textAlign: 'left' }}>{highlightedDescription}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <i className={`bi bi-share fs-4`} style={{ color, marginLeft: '11px' }}></i>
                <p>Share</p>
            </Modal.Footer>
        </Modal>
    );
};

export default BadgeModal;
