import React, { useState, useEffect } from 'react';
import '../../assets/scss/Home.scss'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import SelectedPledge from './SelectedPledge';
import PledgeCard from '../components/PledgeCard';

const generateRandomAmounts = () => {
  const raisedAmount = Math.floor(Math.random() * 200000) + 50000;
  const goalAmount = Math.floor(Math.random() * 500000) + 200000;
  const donationAmounts = [
    Math.floor(Math.random() * 500) + 100,
    Math.floor(Math.random() * 1000) + 500,
  ];

  return { raisedAmount, goalAmount, donationAmounts };
};

const Home = () => {
  const [amounts] = useState(generateRandomAmounts());
  const { id } = useParams()
  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token to get the user id
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [campaignData, setCampaignData] = useState([])

  useEffect(() => {
    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
      .then((res) => {
        setCampaignData(res.data.results[0].campaign_data[0])
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])
  let endDate;
  // if (!campaignData.pledge_frequency) {

  // }
  // else {
  //   endDate = campaignData.installment_end_date

  // }
  endDate = campaignData.expiry_date





  return (
    <>
      <SideBar />
      <Header />
      <div className='homePage'>
        <PledgeCard
          category=""
          title={campaignData.campaign_description}
          raisedAmount={campaignData.total_paid_pledge}
          goalAmount={parseInt(campaignData.original_pledge_amount)}
          donationAmounts={amounts.donationAmounts}
          image={campaignData.campaign_image}
          ends_on={endDate}
          days_remaining={campaignData.remaining_days}
          inkindAmount={campaignData.in_kind_amount}
          contributions={campaignData.contribution_count}
          description={campaignData.campaign_about}
        />
      </div>
    </>

  );
};

export default Home;