import React from 'react';

// cropUtils.js
export const getCroppedImg = (imageFile, crop) => {
  return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      // Create a FileReader to read the image file as a data URL
      const reader = new FileReader();
      reader.onload = (event) => {
          // Set the data URL as the source of the image
          image.src = event.target.result;

          image.onload = () => {
              const scaleX = image.naturalWidth / image.width;
              const scaleY = image.naturalHeight / image.height;

              canvas.width = crop.width;
              canvas.height = crop.height;

              ctx.drawImage(
                  image,
                  crop.x * scaleX,
                  crop.y * scaleY,
                  crop.width * scaleX,
                  crop.height * scaleY,
                  0,
                  0,
                  crop.width,
                  crop.height
              );

              // Convert the canvas content to a blob
              canvas.toBlob((blob) => {
                  resolve(blob);
              }, 'image/jpeg', 1);
          };

          image.onerror = () => {
              reject(new Error('Failed to load image'));
          };
      };

      // Read the image file as a data URL
      reader.readAsDataURL(imageFile);
  });
};

