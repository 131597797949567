// store.js

import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import { campaignReducer, pledgeReducer } from './reducers';

const rootReducer = combineReducers({
  campaignData: campaignReducer,
  pledgeData: pledgeReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
