import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PhoneNumberValidation({ style }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [valid, setValid] = useState(true);
  useEffect(() => {
    // Load saved phone number and country from local storage
    const savedNumber = localStorage.getItem('number');
    const savedCountry = localStorage.getItem('country');

    if (savedNumber && savedCountry) {
      setPhoneNumber(savedNumber);
      setCountry(savedCountry);
    }
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  const handleChange = (value, countryData) => {
    let formattedNumber = value;

    // Check if the entered number starts with '0'
    if (value.startsWith('0')) {
      // Remove the leading '0' and concatenate the country code
      formattedNumber = countryData.dialCode + value.substring(1);
    }

    setPhoneNumber(formattedNumber);
    setValid(validatePhoneNumber(formattedNumber));
    setCountry(countryData.name);

    // Save phone number and country to local storage
    localStorage.setItem('number', formattedNumber);
    localStorage.setItem('country', countryData.name);
    localStorage.setItem('countryCode', countryData.dialCode);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <div>
      <label>
        <PhoneInput
          country={'ke'}
          value={phoneNumber}
          onChange={handleChange}
          inputProps={{
            required: true,
          }}
         style={style ? { ...{ width: "28vw !important" }, ...style } : { width: "28vw !important" }}/>
        
                
      </label>
      {!valid && (
        <p>Please enter a valid phone number.</p>
      )}
    </div>
  );
}

export default PhoneNumberValidation;
