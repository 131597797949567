import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    Button,
    ListGroup,
    ListGroupItem,
    Image,
    Form,
    FormControl,
} from "react-bootstrap";
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function SwitchAccount({ userDetails }) {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const Cooporates = localStorage.getItem('coporates')

    const previousTokens = localStorage.getItem('authTokens')

    const addAccount = () => {
        navigate('/register')
        localStorage.removeItem("authTokens");
        localStorage.setItem("previousTokens", previousTokens)

        if (userDetails.account_type === 'individual' || userDetails.account_type === 'family') {
            localStorage.setItem('newAccount', 'corporate')
            localStorage.setItem('userLinked', userDetails.id)


        }
        else {
            localStorage.setItem('newAccount', 'individual')
            localStorage.setItem('corporateLinked', userDetails.id)


        }

        toast.success('Kindly create the account')
    };

    const switchAccount = () => {
        // Retrieve authTokens and previousTokens from localStorage
        const authTokens = localStorage.getItem('authTokens');
        const previousTokens = localStorage.getItem('previousTokens');

        if (previousTokens !== null && previousTokens.trim() !== '') {
            // Set previousTokens with the value of authTokens
            localStorage.setItem('previousTokens', authTokens);

            // Set authTokens with the value of previousTokens
            localStorage.setItem('authTokens', previousTokens);

            // Display toast message indicating success
            toast.success('Successfully switched account');
        } else {
            // If previousTokens is null or empty, navigate to login page
            navigate('/login');

            // Set previousTokens with the value of authTokens
            localStorage.setItem('previousTokens', authTokens);

            // Display toast message indicating the need to login
            toast.success('Kindly login to the account so that you can switch');
        }

        // Reload the page
        window.location.reload();
    };



    const [linkedAccounts, setLinkedAccounts] = useState({})
    const [linkedAccountsÇounts, setLinkedAccountsÇounts] = useState()

    useEffect(() => {
        if (userDetails.account_type === 'corporate') {
            serverUrl.get(`/user/corporates?corporate_user=${userDetails.id}`, config)
                .then((res) => {
                    setLinkedAccounts(res.data.results[0])
                    setLinkedAccountsÇounts(res.data.count)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            serverUrl.get(`/user/corporates?user=${userDetails.id}`, config)
                .then((res) => {
                    setLinkedAccounts(res.data.results[0])
                    setLinkedAccountsÇounts(res.data.count)


                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [])

    return (
        <div className="mb-4 pt-3 cardProfile">
            <h2>Switch to other account</h2>

            <div className="topData">
                {Cooporates ?
                    <>

                    </>

                    :
                    <>
                        {linkedAccountsÇounts > 0 ?
                            <>
                                {userDetails && (
                                    userDetails.account_type === "individual" || userDetails.account_type === "family" ?
                                        <Button className='btn btn-dark btn-sm text-white' onClick={switchAccount}>Switch to a Corporate Account</Button>
                                        :
                                        <Button className='btn btn-dark btn-sm text-white' onClick={switchAccount}>Switch to an Individual Account</Button>
                                )

                                }
                            </>
                            :
                            <>

                                {userDetails && (
                                    userDetails.account_type === "individual" || userDetails.account_type === "family" ?
                                        <p>Add a corporate account</p>
                                        :
                                        <p>Add an indiviual account</p>)

                                }
                                <Button className="btn btn-sm btn-danger text-white border-0" onClick={addAccount}>Create Account <i className="bi bi-plus"></i></Button>
                            </>
                        }


                    </>
                }


            </div>



        </div>
    );
}

export default SwitchAccount;
