import React from 'react';
import ReactMarkdown from 'react-markdown';

const YourComponent = () => {
  const markdownText = `
  **CHRIST IS THE ANSWER MINISTRIES (CITAM)**

**Last updated: April 4, 2024**

This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the Service and tells you about your privacy rights.

We use your Personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy is maintained by the Church.



**CHURCH** means Christ Is The Answer Ministries (CITAM) a registered Society.
--------------- --------------------------------------------------------
**DPA** Means the Data Protection Act Cap No.24 of 2019

**You** means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.

**Account** means a unique account created for you to access our Service or parts of our Service.

**Personal Data** is any information that relates to an identified or identifiable individual.
**GDPR** means the General Data Protection Regulation.
**Responsible Person** means the CITAM ICT Manager
**Register of Systems** means a register of all systems or contexts in which personal data is processed by the Church.

------------------------------------------------------------------------

**1.  Data protection principles**

The Church is committed to processing data in accordance with its responsibilities under the GDPR and the DPA.

Article 5 of the GDPR requires that personal data shall be:

1. processed lawfully, fairly and in a transparent manner in relation to individuals;
2. collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes;
3. adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;
4. accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay;
5. kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes subject to implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals; and
6. processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures”

**2.  Types of Data Collected**

**a. Personal Data**

While using Our Service, we may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally, identifiable information may include, but is not limited to:

1. First name and last name
2. Phone number
3. Email
4. Gender
5. ID Number / Passport number

The above data shall be used strictly within CITAM and only for the purpose of:

1. Identification: (Fist and Last names, ID/Passport Number)
2. Communication: (Phone Number and Email Account)
3. Analytics (Gender)

**b.  Usage Data**

&nbsp;Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

**c.   General Provisions**

1. This policy applies to all personal data processed by the church
2. The Responsible Person shall take responsibility for the Church’s ongoing compliance with this policy.
3. This policy shall be reviewed at least annually.

**4.  Lawful, fair and transparent processing**

1. To ensure its processing of data is lawful, fair and transparent, the Church shall maintain a Register of
2. The Register of Systems shall be reviewed at least
3. Individuals have the right to access their personal data and any such requests made to the Church shall be dealt with in a timely

**5\. Lawful purposes**

1. All data processed by the Church must be done on one of the following lawful bases: consent, contract, legal obligation, vital interests, public task or legitimate interests
2. The Church shall note the appropriate lawful basis in the Register of Systems.
3. Where consent is relied upon as a lawful basis for processing data, evidence of opt-in consent shall be kept with the personal data.
4. Where communications are sent to individuals based on their consent, the option for the individual to revoke their consent should be clearly available and systems should be in place to ensure such revocation is reflected accurately in the Church’s systems.

**6\. Data minimisation**

1. The Church shall ensure that personal data are adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed.
2. The record shall primarily be for the setting up a register of members for purposes of legal compliance with the Society Act and for purposes of maintaining a member records for purposes of our RAGMs and ADC.

**7.  Accuracy**

1. The Church shall take reasonable steps to ensure personal data is
2. Where necessary for the lawful basis on which data is processed, steps shall be put in place to ensure that personal data is kept up to date

**8\. Archiving / removal**

1. To ensure that personal data is kept for no longer than necessary, the Church shall put in place an archiving policy for each area in which personal data is processed and review this process bi-annually.
2. The archiving policy shall consider what data should/must be retained, for how long, and why.

**9\. Security**

1. The Church shall ensure that personal data is stored securely using modern software that is kept-up-to-date.
2. Access to personal data shall be limited to personnel who need access and appropriate security should be in place to avoid unauthorised sharing of information.
3. When personal data is deleted this should be done safely such that the data is irrecoverable.
4. Appropriate back-up and disaster recovery solutions shall be in place.

**10.   Breach**

In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data, the Church shall promptly assess the risk to people’s rights and freedoms.  

`;
  return (
    <div>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </div>
  );
}

export default YourComponent;
