import React, { useEffect, useState } from 'react';
import '../../assets/scss/Airtime.scss';
import { FormControl, Button } from 'react-bootstrap';
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

function Airtime() {
    const goBack = () => {
        window.history.back();
    };

    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };

    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [userData, setUserData] = useState({});
    const [phoneNumber, setPhone] = useState('');
    const [amount, setAmount] = useState();

    useEffect(() => {
        serverUrl
            .get(`/user/user-details/?id=${userId}`, config)
            .then((res) => {
                const defaultPhoneNumber = res.data.results[0].phone_number ? res.data.results[0].phone_number.startsWith('+254') ? '0' + res.data.results[0].phone_number.slice(4) : res.data.results[0].phone_number : '';

                setUserData(res.data.results[0]);
                setPhone(defaultPhoneNumber);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [userId]);

    function handleAirtime(e) {
        e.preventDefault();

        // Ensure both amount and phone number are provided
        if (!amount || !phoneNumber) {
            toast.error('Please enter both amount and phone number.');
            return;
        }

        // Validate phone number length
        if (phoneNumber.length !== 10) {
            toast.error('Phone number should be 10 digits.');
            return;
        }
        const userPhoneNumberMpesa = userData.phone_number ? userData.phone_number.startsWith('+254') ? userData.phone_number.slice(1) : userData.phone_number : ''

        // Ensure phone number always starts with '+254' and replace '0' with '254'
        const formattedPhoneNumber = phoneNumber.startsWith('0') ? '+254' + phoneNumber.slice(1) : phoneNumber;

        const data = {
            description: `credit-${userId}-citam`,
            phone_number:  userData.phone_number,
            church: 7,
            reference: formattedPhoneNumber,
            amount: amount,
            source: 'web',
        };

        serverUrl
            .post(`https://jumuisha.net/api/giving/checkout/`, data, config)
            .then((res) => {
                toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            <div className='mobileAirtime'>
                <div className='mobileAirtimeHeader'>
                    <p onClick={goBack} className='d-flex'>
                        <i className='bi bi-chevron-left' style={{ fontSize: '2rem', fontWeight: '800', marginTop: '0.5rem' }}></i>
                    </p>
                    <h2>Buy Airtime</h2>
                </div>

                <div className='mobileAirtimeBody'>
                    <label>Buy airtime for myself or key in phone number</label>
                    <FormControl type='tel' placeholder='07XXXXX' value={phoneNumber} onChange={(e) => setPhone(e.target.value)} maxLength={10} />

                    <label>Enter Amount</label>
                    <FormControl type='number' value={amount} onChange={(e) => setAmount(e.target.value)} />
                    {amount < 10 || amount > 10000 ? (
                        <p className='text-danger fw-bold'>Amount should be between 10 and 10,000</p>
                    ) : (
                        <></>
                    )}
                    {amount >= 10 && amount <= 10000 ? (
                        <Button className='btn btn-sm btn-danger text-white w-100' onClick={handleAirtime}>
                            BUY KSH. {parseInt(amount).toLocaleString('en-US')} AIRTIME
                        </Button>
                    ) : (
                        <Button className='btn btn-sm btn-danger text-white w-100' disabled>
                            BUY AIRTIME
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Airtime;
