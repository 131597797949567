import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom'
import Home from './screens/main/Home'
import Login from './screens/auth/Login'
import Register from './screens/auth/Register'
import Campaign from './screens/main/Campaign'
import MobilePledgeCampaign from './screens/main/MobilePledgeCampaign'
import SharedPledge from './screens/main/SharedPledge'
import Donation from './screens/main/Donation'
import NewHome from './screens/main/NewHome'
import ForgotPassword from './screens/auth/ForgotPassword'
import LandingPage from './screens/main/LandingPage'
import ImageCropper from './screens/components/ImageCropper'
import MyPledges from './screens/components/MyPledges'
import Groups from './screens/main/Groups'
import More from './screens/main/More'
import Profile from './screens/main/Profile'
import SharedCampaign from './screens/main/SharedCampaign'
import MyActivePledges from './screens/main/MyActivePledges'
import DonationShared from './screens/main/DonationShared'
import HelpDesk from './screens/main/HelpDesk'
import ReportIssue from './screens/main/ReportIssue'
import Airtime from './screens/main/Airtime';
import BuyAirtime from './screens/main/BuyAirtime';

function WebRoutes() {
  const isAuthenticated = localStorage.getItem('authTokens');

  useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== '/login' && window.location.pathname !== '/register' && window.location.pathname !== '/register/:id' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/') {
      window.location.href = '/#/login'; // Redirecting using window.location.href
    }
  });

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={isAuthenticated ? <NewHome /> : <LandingPage />} />
        <Route path="/home" element={<NewHome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:id" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/pledge/:id" element={<Home />} />
        <Route path="/mobile-pledge" element={<MobilePledgeCampaign />} />
        <Route path="/shared-pledge/:id" element={<SharedPledge />} />
        <Route path="/donation/:id" element={<Donation />} />
        <Route path="/donation-shared/:id" element={<DonationShared />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/images" element={<ImageCropper />} />
        <Route path="/active-pledges" element={<MyPledges />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/more" element={<More />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/shared-campaign" element={<SharedCampaign />} />
        <Route path="/help-desk" element={<HelpDesk />} />
        <Route path="/report-issue" element={<ReportIssue />} />
        <Route path="/my-active-pledges" element={<MyActivePledges />} />
        <Route path="/buy-airtime" element={<Airtime />} />
        <Route path="/buy-airtime-home-ios/myself/:phone/7/:token/:user" element={<BuyAirtime />} />
      </Routes>
    </HashRouter>
  );
}

export default WebRoutes;
