// Login.jsx

import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Image, Row, Col, Spinner } from 'react-bootstrap';
import '../../assets/scss/Login.scss';
import { Link, useParams } from "react-router-dom";
import Citam from '../../assets/img/citam_default.png'
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import PhoneNumberValidation from '../components/PhoneNumberValidation';
import TermsConditions from '../components/TermsConditions';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Lottie from 'react-lottie';
import ChatMessage from '../../assets/lottie/chat_message.json';
import LiveChat from '../../assets/img/live-chat.png'

function Login() {
  let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
  let [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
  });
  const yellowHappyFace = '\u{1F600}';


  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const phone_number = localStorage.getItem('number')

    const loginData = {
      phone_number: '+' + phone_number,
      password: formData.password,
    }
    setLoading(true)

    serverUrl.post(`/user/login/`, loginData)
      .then((res) => {
        const data = res.data.data
        setAuthTokens(data.tokens)
        setUser(jwtDecode(data.tokens.access))
        localStorage.removeItem('number')
        localStorage.setItem('authTokens', JSON.stringify(data.tokens))
        toast.success(`Welcome to together in generosity campaign ${yellowHappyFace}`)
        navigate('/home')
        setLoading(false)
        localStorage.removeItem('help_requester')
        localStorage.removeItem('user')
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        if (!error.response || !error.response.status) {
          toast.error("An error occurred. Please try again later.");
        } else if (error.response.status === 401) {
          toast.error("Sorry, the entered credentials are not correct.");
        }
      });

  };


  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // modal to display the terms sand conditions
  const [showModalTerms, setShowModalTerms] = useState(false);

  const handleOpenModalTerms = () => {
    setShowModalTerms(true);
  };

  const handleCloseModalTerms = () => {
    setShowModalTerms(false);
  };

  const [showModalPolicy, setShowModalPolicy] = useState(false);

  const handleOpenModalPolicy = () => {
    setShowModalPolicy(true);
  };

  const handleCloseModalPolicy = () => {
    setShowModalPolicy(false);
  };
  const goBack = () => {
    window.history.back();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ChatMessage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // open the modal for the phone and full name
  const [showModal, setShowModal] = useState(false)
  const [fullName, setFullName] = useState('')


  const handleOpenModal = () => {
    setShowModal(true)
    localStorage.removeItem('help_requester')
    localStorage.removeItem('user')
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  function handleAddDetails(e) {
    e.preventDefault()
    const phone_number = localStorage.getItem('number')
    const formData = new FormData
    formData.append('phone_number',phone_number)
    formData.append('name',fullName)

  
    serverUrl.post(`/user/create-support-requester/`, formData)
      .then((res) => {
        localStorage.removeItem('number')
        if(res.data.help_requester){
          localStorage.setItem('help_requester',res.data.help_requester)
        
        }
        else{
          localStorage.setItem('user',res.data.user)

        }
        toast.success('Your details have been recorded succesfully')
        setShowModal(false)
        navigate('/help-desk')

      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  return (
    <div className='mainLogin'>
      <div className='LoginPage'>

        {/* Add more bubbles as needed */}
        <Image src={Citam} style={{ width: "5%" }} />
        <Row>
          <div className='col-6'>
            {/* Login form */}
            <Form onSubmit={handleLogin} className='loginForm'>
              <h2>Log In</h2>
              <label>Phone Number<b className='text-danger'>*</b></label>
              <PhoneNumberValidation className='mobileCountryPicker' />

              <label>Password<b className='text-danger'>*</b></label>

              <div className='d-flex'>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  placeholder="Enter password"

                  onChange={handleFormInput}
                />
                <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
                  {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                </span>
              </div>

              <Link className='forgotLink' to='/forgot-password'>Forgot password?</Link>
              {loading ?

                <Button className='btn btn-danger btn-muted text-white' type='submit' onClick={handleLogin}><Spinner /></Button>

                :
                <Button className='btn btn-danger text-white' type='submit' onClick={handleLogin}>LOGIN</Button>

              }


              <Link to='/register' >Don't have an account?<u> Register</u></Link>


            </Form>
          </div>

          <div className='col-6'>

            <div className='curvedSpan' >
              <div className='textDisplay'>
                <div className='InnerDivDisplay'>
                  <h2>Welcome to our Together in generosity Campaign!</h2>

                  <div className='displayParagraph'>
                    <br></br>
                    <p>Together in Generosity (TIG) Campaign is to raise the funds/resources to facilitate the settling of 23 assemblies that are in tents while others are on leased premises</p>
                    <p>
                      <b>Login now </b>to access exclusive updates, participate in fundraising events, and contribute to our together in generosity campaign.

                    </p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </Row>




      </div>

      <div className='LoginPageMobile'>

        <div className='d-flex justify-content-between'>
          <p onClick={goBack}>
            <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem" }}></i>
          </p>

          <Image src={Citam} />
        </div>
        {/* Floating bubbles */}
        <figure class="bubble6"></figure>

        {/* Login form */}
        <Form onSubmit={handleLogin} className='loginForm'>
          <h2>Log In</h2>
          <label>Phone Number</label>
          <PhoneNumberValidation className='mobileCountryPicker' />

          <label>Password</label>

          <div className='d-flex'>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              placeholder="Enter password"

              onChange={handleFormInput}
            />
            <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
              {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
            </span>
          </div>

          <Link className='forgotLink' to='/forgot-password'>Forgot password?</Link>
          {loading ?

            <Button className='btn btn-danger btn-muted text-white' type='submit' onClick={handleLogin}><Spinner /></Button>

            :
            <Button className='btn btn-danger text-white' type='submit' onClick={handleLogin}>LOGIN</Button>

          }
          <div className='bottomRegister'>
            <Link to=''>Don't have an account?</Link>

            <Link to='/register' >
              <Button className='btn btn-dark text-white'>REGISTER</Button>

            </Link>
            <span>By creating an account,you are agreeing to our <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Conditions</b> and <b style={{ color: "#409fff" }} onClick={handleOpenModalPolicy}>Privacy Policy</b></span>
          </div>


        </Form>

        <Button className='btn d-flex btn-danger text-white btn-sm align-items-center' onClick={handleOpenModal}
          style={{ width: "auto", borderRadius: "20px", fontSize: "10px", padding: "3px", position: "absolute", top: "50px", left: "10px" }}>
          <Image src={LiveChat} style={{ width: "9vw" }} />
          <span className='fw-bolder' style={{ marginInline: "3px", }}>Help Desk</span>
        </Button>
  


        <Modal show={showModal} onHide={handleCloseModal} centered size='xm' style={{width:"70vw",marginLeft:"4rem"}}>
          <Modal.Header closeButton>
            <Modal.Title>Enter details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <label>Full Name</label>
              <Form.Control
                type='text'
                name="fullName"
                value={fullName}
                placeholder="Enter your name"
                onChange={(e) => setFullName(e.target.value)}
              />

              <label>Phone Number</label>
              <PhoneNumberValidation className='mobileCountryPicker' />

            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="success" onClick={handleAddDetails}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='xm' centered>
          <Modal.Header closeButton>
            <Modal.Title>Terms And Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TermsConditions />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseModalTerms}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={showModalPolicy} onHide={handleCloseModalPolicy} size='xm' centered>
          <Modal.Header closeButton>
            <Modal.Title>Privacy Policy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseModalPolicy}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>


    </div>
  );
}

export default Login;
