import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChartDashboard = ({ percentage, colorRedemption, colorPledge }) => {
  let color;

  if (colorPledge && percentage > 0) {
    color = ['#e67e22', '#9e9e9e30']
  }
  else if (colorRedemption && percentage > 0) {
    color = ['#8e44ad', '#9e9e9e30']
  }
  else {
    if (percentage < 33) {
      color = ['#e74c3c', '#9e9e9e30'];
    } else if (percentage >= 33 && percentage <= 67) {
      color = ['#f39c12', '#9e9e9e30']
    } else {
      color = ['#2ecc71', '#9e9e9e30'];
    }
  }

  return (
    <div className="pie-chart-container" style={{ position: 'relative' }}>
      <ReactApexChart
        options={{
          labels: ['Raised Amount', 'Goal Amount'],
          colors: color,
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            lineCap: 'round',
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  // height: 180,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }}
        series={[percentage, 100 - percentage]}
        type="donut"
        width={300}
        height={150}
      />
      <div
        className="percentage-text"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '15px',
          color: 'black',
          fontWeight: '600',
        }}
      >
        {percentage.toFixed(2)}%
      </div>
    </div>
  );
};

export default PieChartDashboard;
