import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import SideBar from '../components/Sidebar';
import { Col, Container, Row, Table, Button } from 'react-bootstrap';
import PageTitle from "../components/PageTitle";
import UserDetails from '../components/UserDetails';
import UserAccountDetails from '../components/UserAccountDetails';
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import '../../assets/scss/Profile.scss'
import SwitchAccount from './SwitchAccount';
import MobileProfile from './MobileProfile';

function Profile() {
    const [activeTab, setActiveTab] = useState('myAccount');
    const [userDetails, setUserDetails] = useState({});
    const [userAccountDetails, setUserAccountDetails] = useState({});
    const accessToken = localStorage.getItem('authTokens');
    if (!accessToken) {
        window.location.href = '/#/login';
        return;
      }
    // Fetch user details
    useEffect(() => {
      
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        const config = {
            headers: { Authorization: `Bearer ${access}` },
        };
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;

        serverUrl.get(`/user/user-details/?id=${userId}`, config)
            .then((res) => {
                setUserDetails(res.data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });

        // You might need to fetch account details here based on the activeTab

    }, [activeTab]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // You can add logic to fetch account details based on the tab here
    };

    return (
        <>
            <Header />
            <SideBar />
            <div className='profile'>
                <h2>Account Settings</h2>
                <Row className='ProfileDetails'>
                    <Col md={2} style={{ borderRight: "2px solid lightgray", marginTop: "2rem", maxHeight: "fit-content" }}>
                        <div className="sidebar-menu">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Button className={` btn btn-sm nav-link-profile ${activeTab === 'myAccount' ? 'active-profile' : ''}`} onClick={() => handleTabChange('myAccount')}>
                                        My Account
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button className={` btn btn-sm nav-link-profile ${activeTab === 'profileAccount' ? 'active-profile' : ''}`} onClick={() => handleTabChange('profileAccount')}>
                                        Profile Account
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button className={` btn btn-sm nav-link-profile ${activeTab === 'switchAccount' ? 'active-profile' : ''}`} onClick={() => handleTabChange('switchAccount')}>
                                        Switch Account
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="profile-content">
                            {activeTab === 'myAccount' && <UserDetails userDetails={userDetails} />}
                            {activeTab === 'profileAccount' && <UserAccountDetails />}
                            {activeTab === 'switchAccount' && <SwitchAccount userDetails={userDetails} />}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='mobileProfile'>
                <MobileProfile />
            </div>
        </>
    );
}

export default Profile;
