import React from 'react'
import SideBar from '../components/Sidebar'
import Header from '../components/Header'
import MyPledges from '../components/MyPledges'
import '../../assets/scss/MyActivePledges.scss'

function MyActivePledges() {
  return (
    <>
     
      <div className='activePledges'>
      <SideBar />
      <Header />
      <MyPledges
    
      />
      </div>
   

    </>
  )
}

export default MyActivePledges